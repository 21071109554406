import React, { useEffect } from 'react';

import Modal, { ModalActions, ModalContent, ModalHeader, ModalSubtitle, ModalTitle, } from "../components/Modal";
import { Button } from "../components/Button";
import cx from "classnames";
// ===========================
// COMPONENT
// ===========================

export const ConfirmModal = ({
  onRequestClose, confirmLabel = "Ok", cancelLabel = "Cancel",
  onConfirm, onCancel, title, message, data, size = "medium"
}) => {

  const onConfirmClick = () => {
    onConfirm?.(data);
    onRequestClose();
  }

  const onCancelClick = () => {
    onCancel?.();
    onRequestClose();
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        onConfirmClick();
      } else if (event.key === 'Escape') {
        onCancelClick();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <Modal className={cx("ConfirmModal", size)} onRequestClose={onCancelClick}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        {message && <div className={"short-content"}>{message}</div>}

        <ModalActions>
          <Button color={"green"} role={'submit'} onClick={onConfirmClick} title={confirmLabel} />
          <Button color={"transparent"} onClick={onCancelClick} title={cancelLabel} />
        </ModalActions>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmModal;
