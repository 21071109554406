export const ChatbotColors = [
    { key: '--color-background', default: '#1E1E1E', light: '#FFFFFF', vgk: '#1A1A1F' },
    { key: '--color-input-focus-border', default: '#4DA3FF', light: '#007AFF', vgk: '#B8965B' },
    { key: '--color-border', default: '#3A3A3C', light: '#E5E5EA', vgk: '#34343D' },
    { key: '--color-input-background', default: '#2C2C2E', light: '#F2F2F7', vgk: '#2F2F38' },
    { key: '--color-user-message-background', default: 'rgba(77, 163, 255, 0.2)', light: 'rgba(0, 122, 255, 0.1)', vgk: '#B8965B' },
    { key: '--color-assistant-message-background', default: '#3A3A3C', light: '#E5E5EA', vgk: '#2F2F38' },
    { key: '--color-send-button-background', default: '#9898b5', light: '#C7C7CC', vgk: '#9898b5' },
    { key: '--color-send-button-background-active', default: '#4DA3FF', light: '#007AFF', vgk: '#B8965B' },
    { key: '--color-loader', default: '#4DA3FF', light: '#007AFF', vgk: '#9898B5' },
    { key: '--color-user-message-text', default: '#4DA3FF', light: '#007AFF', vgk: '#000000' },
    { key: '--color-assistant-message-text', default: '#ffffff', light: '#1F1F21', vgk: '#9898B5' },
    { key: '--color-secondary-buttons-background', default: '#4DA3FF', light: '#007AFF', vgk: '#9898b5' },
    { key: '--color-input-text', default: '#FFFFFF', light: '#1F1F21', vgk: '#FFFFFF' },
    { key: '--color-preset-question-background', default: '#2F2F38', light: '#F2F2F7', vgk: '#2F2F38' },
    { key: '--color-preset-question-text', default: '#9898B5', light: '#8E8E93', vgk: '#9898B5' },
    { key: '--color-preset-question-background-hover', default: '#322D27', light: '#E5E5EA', vgk: '#322D27' },
    { key: '--color-preset-question-text-hover', default: '#B8965B', light: '#007AFF', vgk: '#B8965B' },
    { key: '--color-global-wrapper-background', default: '#000000', light: '#ffffff', vgk: '#000000' },
    { key: '--color-header-slogan', default: '#ffffff', light: '#000000', vgk: '#ffffff' }
  ];

  