import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { isEmpty, isNil, get as _get } from 'lodash';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import Ai from '../store/models/Ai';
import { InfoIcon } from '../components/Svg';
import Tooltip from '../components/Tooltip';

export const CreateDocumentModal = ({ projectId, chatbotId, onRequestClose, onSuccess, withSkip }) => {
    const dispatch = useDispatch();
    const [contentTab, setContentTab] = useState('file');
    const [newData, setNewData] = useState({});
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [metadataTab, setMetadataTab] = useState('standard');
    const [metadataJson, setMetadataJson] = useState(`{
    "description": "document_description", 
    "my_property": "my_property_value"
}`);
    const [jsonError, setJsonError] = useState('');

    const handleTextChange = (event) => {
        const text = event.target.value;
        setNewData(d => ({
            ...d,
            text
        }));
    };

    const handleAdd = async () => {
        setError();
        let text = _get(newData, 'text');
        let file = _get(newData, 'file');
        let description = _get(newData, 'description');
        let metadata = _get(newData, 'metadata');

        let body = {
            project_id: projectId,
        }

        if (isEmpty(text) && isNil(file)) {
            setError('Either file or text must be provided');
            return;
        }

        switch (contentTab) {
            case 'text':
                body.text = text;
                body.metadata = {
                    type: "text",
                }
                break;
            case 'file':
                body.file = file
                body.metadata = {
                    type: "file",
                    filename: file.name,
                }
                break;
            default:
                setError('Either file or text must be provided');
                return;
        }

        switch (metadataTab) {
            case 'standard':
                body.metadata.description = description ?? "";
                break;
            case 'advanced':
                body.metadata = { ...body.metadata, ...metadata };
                break;
        }

        body.metadata = JSON.stringify(body.metadata);

        setLoading(true);
        try {
            const document = await dispatch(Ai.actions.createChatbotDocument(chatbotId, body));
            onSuccess?.(document);
            toast.success('The new document has been added. Add more documents in the Knowledge Base tab.');
            onRequestClose();
        } catch (e) {
            setError(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal className="CreateDocumentModal m" onRequestClose={onRequestClose}>
            <ModalHeader>
                <ModalTitle>Add New Document to Knowledge Base</ModalTitle>
            </ModalHeader>

            <ModalContent>

                <div className='CreateDocumentModal--label'>Document's content</div>
                <div className='CreateDocumentModal--tabs'>
                    <div
                        className={cx("CreateDocumentModal--tab", { active: contentTab === 'file' })}
                        onClick={() => setContentTab('file')}
                    >
                        File
                    </div>
                    <div
                        className={cx("CreateDocumentModal--tab", { active: contentTab === 'text' })}
                        onClick={() => setContentTab('text')}
                    >
                        Text
                    </div>
                </div>

                {contentTab === 'file' && <FileInput newData={newData} setNewData={setNewData} setError={setError} />}
                {contentTab === 'text' && (
                    <textarea
                        type="text"
                        placeholder="Enter document's text here..."
                        className='CreateRAGChatbotModal__textarea'
                        onChange={handleTextChange}
                        value={newData?.text || ''}
                    />
                )}


                <div className='CreateDocumentModal--label top-margin'>Document's metadata</div>
                <div className='CreateDocumentModal--tabs'>
                    <div
                        className={cx("CreateDocumentModal--tab", { active: metadataTab === 'standard' })}
                        onClick={() => setMetadataTab('standard')}
                    >
                        Standard <Tooltip tooltip="Add a description, tags and context for your document in plain text">
                            <InfoIcon className="CreateDocumentModal--info-icon" />
                        </Tooltip>
                    </div>

                    <div
                        className={cx("CreateDocumentModal--tab", { active: metadataTab === 'advanced' })}
                        onClick={() => setMetadataTab('advanced')}
                    >
                        Advanced<Tooltip tooltip="Add custom metadata in JSON format for more detailed document information">
                            <InfoIcon className="CreateDocumentModal--info-icon" />
                        </Tooltip>
                    </div>

                </div>

                {metadataTab === 'standard' ? (
                    <textarea
                        type="text"
                        placeholder='Enter additional context or tags for this document...'
                        className='CreateRAGChatbotModal__textarea CreateRAGChatbotModal__textarea--metadata'
                        onChange={(e) => setNewData(d => ({ ...d, description: e.target.value }))}
                        value={newData?.description || ''}
                    />
                ) : (
                    <>
                        <textarea
                            type="text"
                            placeholder='Enter metadata as JSON...'
                            className='CreateRAGChatbotModal__textarea CreateRAGChatbotModal__textarea--metadata'
                            style={{ fontFamily: 'monospace' }}
                            onChange={(e) => {
                                setMetadataJson(e.target.value);
                                setJsonError('');
                                try {
                                    const metadata = JSON.parse(e.target.value);
                                    setNewData(d => ({ ...d, metadata }));
                                } catch (err) {
                                    setJsonError('Invalid JSON format');
                                }
                            }}
                            value={metadataJson}
                        />
                        {jsonError && (
                            <div className='CreateDocumentModal--error'>{jsonError}</div>
                        )}
                    </>
                )}

                {error && <div className="NewFormError">{error}</div>}

                <ModalActions>
                    <Button
                        color="green"
                        onClick={handleAdd}
                        loading={loading}
                        disabled={loading || (isEmpty(newData.text) && isNil(newData.file))}
                        title="Add Document"
                    />
                    <Button color={withSkip ? "grey" : "transparent"} onClick={onRequestClose} title={withSkip ? "Skip" : "Cancel"} />
                </ModalActions>
            </ModalContent>
        </Modal>
    );
};

const FileInput = ({ newData, setNewData, setError }) => {
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
        accept: ".doc,.pdf,.docx,.txt,.html,.json,.csv,.md",
    });

    useEffect(() => {
        (async () => {
            setError();
            const newFile = acceptedFiles[0];
            if (!newFile) return;
            setNewData(d => ({ ...d, file: newFile }))
        })();
    }, [acceptedFiles]);

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [isDragActive, isDragReject, isDragAccept]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {newData.file
                    ? <div key={newData.file.path} style={{ color: '#18C99D' }}>
                        {newData.file.path}
                    </div>
                    : <p>Drag & drop or <span className={"choose-file-text"}>Choose a file</span> to upload<br />
                        Supported File Types: .pdf, .doc., docx., .txt, .html, .json, .csv, .md
                    </p>
                }
            </div>
        </div>
    );
};

const baseStyle = {
    height: '150px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 14,
    cursor: 'pointer',
    borderColor: '#282B3B',
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    color: '#636B91',
    outline: 'none',
    padding: '0 1rem',
    textAlign: 'center',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#18C99D'
};

const acceptStyle = {
    borderColor: '#18C99D'
};

const rejectStyle = {
    borderColor: '#ff1744'
}; 