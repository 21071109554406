import React from 'react';
import cx from 'classnames';

const StepBar = ({ steps, activeStep, className }) => {
  return (
    <div className={cx("step-bar", className)}>
      <div className="step-wrap">
        {steps.map((step, i) => (
          <React.Fragment key={i}>
            <div className="step-container">
              <div className={`step-label ${i <= activeStep ? 'active' : ''}`}>{step.name}</div>
              <div className={`step-button ${i <= activeStep ? 'active' : ''}`}>
              </div>
            </div>
            {i < steps.length - 1 && (
              <div className={`step-separator ${activeStep >= i + 1 ? 'active' : ''}`} />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default StepBar;
