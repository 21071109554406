import _ from 'lodash';
import {useLocation} from 'react-router-dom';


// HOOK TO GET QUERY PARAMS
export function useQuery() {
  let query = new URLSearchParams(useLocation().search);
  let entries = query.entries();
  const result = {}
  for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}


export function getHost() {
  let l = window?.location;
  return `${l.protocol}//${l.host}`;
}

export function getPathAndQuery() {
  let location = window?.location; // beaware of server-side rendering
  return `${location?.pathname}${location?.search}`;
}

export function getFirstSubdomain() {
  let subdomain = window?.location?.host?.split?.('.')?.[1]
    ? window?.location?.host?.split?.('.')?.[0]
    : null;
  return subdomain;
}

export function getParam(sParam, url) {
  let sPageURL = url ? url : window.location.search.substring(1);
  let sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    let sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] == sParam) {
      return sParameterName[1];
    }
  }
}

export function getQueryParams(search) {
  if (!search || search.indexOf('=') === -1)
    return {};
  let hashes = search.slice(search.indexOf('?') + 1).split('&')
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split('=');
    return {...params, [key]: decodeURIComponent(val)};
  }, {});
}

export function getPathWithoutQuery(url) {
  let pathOnly = url
    ? url.split('?')[0]
    : window?.location?.pathname;
  return pathOnly;
}


export function forceHTTPS(url) {
  url = _.toLower(url ?? '');
  if (url.indexOf('http:') < 0)
    return url;
  return _.replace(url, 'http:', 'https:');
}


export function paramsToString(obj) {
  let parts = [];
  for (let i in obj) {
    let value = obj[i];
    if (_.isNil(value))
      continue;
    value = _.castArray(value);
    value.forEach(v => {
      if (obj.hasOwnProperty(i) && !_.isNil(obj[i])) {
        parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(v));
      }
    })
  }
  return parts.join("&");
}


export function mergeUrl(url, params, urlMap) {
  if (!url) {
    console.error("URL is not defined for action: ", params);
    return;
  }
  if (!params)
    return url;
  params = {...params};
  if (urlMap) {
    _.keys(urlMap).forEach(key => {
      params[urlMap[key]] = params[key];
      delete params[key];
    });
  }
  if (url.indexOf(":") !== -1) {
    _.forEach(url.match(/:([0-9_a-z]+)/gi), match => {
      let key = match.replace(":", "");
      if (_.isNil(params[key]))
        return;
      url = url.replace(match, params[key]);
      delete params[key];
    });
  }

  if (_.keys(params).length > 0) {
    if (url.indexOf("?") !== -1) {
      url = url + "&" + paramsToString(params);
    } else {
      url = url + "?" + paramsToString(params);
    }
  }
  return url;
}

export function pathBefore(path, segment) {
  if (!path) return undefined;
  if (!segment) return path;
  return path.split(segment)[0];
}

export function isValidUrlRegExp(url) {
  const pattern = new RegExp('^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$', 'i');
  return !!pattern.test(url);
}

export const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

export const isVideoPlatformUrl = (url) => {
  return (url.includes('youtube.com') || url.includes('youtu.be')) ||
    (url.includes('vimeo.com')) ||
    (url.includes('theta.tv'));
};

export const urlSlug = str => _.replace(_.replace(_.toLower(str), /\s/g, '-'), /[^a-z,\-,0-9]/g, "")

export const fileNameFromUrl = (url) => {
  return url.substring(url.lastIndexOf('/') + 1);
}

export function getUrlParams() {
  const params = {};
  const path = window.location.pathname;
  const pathParts = path.split('/').filter(Boolean);
  
  // Get URL parameters from the current location's pathname
  if (pathParts.length > 0) {
    params.projectId = pathParts[2]; // Based on the URL structure /dashboard/ai/:projectId/...
    if (pathParts.length > 4) {
      params.id = pathParts[4]; // For the chatbot id
    }
  }
  
  // Get query parameters
  const searchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }
  
  return params;
}
