import _ from 'lodash';
import { authHeaders, del, get, post, put } from "../../utils/fetch";
import { Hosts, RequestState } from "../../constants";
import { pushTo } from "../history";
import { Urls } from "../../constants";

// ===========================
// HELPERS
// ===========================
export const StorageLocationOptions = [
    { value: 'us-central-1', label: 'US Central 1' },
    { value: 'us-west-1', label: 'US West 1' },
    { value: 'us-east-1', label: 'US East 1' },
];

export const StorageSizeOptions = [
    { value: '512000', label: '512GB' },
    { value: '1000000', label: '1TB' },
    { value: '5000000', label: '5TB' },
    { value: '10000000', label: '10TB' },
];

export function convertSizeLabel(sizeLabel) {
    if (!sizeLabel) return sizeLabel;

    const match = sizeLabel.match(/^(\d+)(.)i$/);
    if (!match) return sizeLabel;

    const [, size, unit] = match;
    return `${size}${unit}B`;
}

export const getFormateStorageSize = (storage) => {
    if (!storage) return '-';

    const size = convertSizeLabel(storage.StorageCapacity);
    return size;
}

// ===========================
// ACTIONS
// ===========================
export const FETCH_STORAGES = 'FETCH_STORAGES';
export const FETCH_STORAGE = 'FETCH_STORAGE';
export const CREATE_STORAGE = 'CREATE_STORAGE';
export const UPDATE_STORAGE = 'UPDATE_STORAGE';
export const DELETE_STORAGE = 'DELETE_STORAGE';
export const FETCH_REGIONS = 'FETCH_REGIONS';
export const GET_DEPLOYMENTS_USING_VOLUME = 'GET_DEPLOYMENTS_USING_VOLUME';
export const GET_DEPLOYMENT_VOLUMES = 'GET_DEPLOYMENT_VOLUMES';
export const GET_STORAGE_PRICE = 'GET_STORAGE_PRICE';

// ===========================
// SELECTORS
// ===========================
export const selectAllStorages = (state) => {
    return _.values(state.models.storages)
};

export const selectStorage = (state, storageId) => {
    const storage = state.models.storages[storageId];
    return storage ? { ...storage } : null;
};

export const selectTotalStoragePages = (state) => {
    return state.models.totalStoragePages;
}

export const selectDeploymentsUsingVolume = (state) => {
    return state.models.deploymentsUsingVolume;
}

export const selectAllRegions = (state) => {
    return _.map(_.filter(state.models.regions, r => r.storageType !== 'none'), region => region.region)
}

export const selectAllDetailedRegions = (state) => {
    return state.models.regions;
}

// ===========================
// MODEL
// ===========================
const AiStorage = {
    actions: {
        fetchStorages: (projectId, page = 1, number = 10) => async (dispatch, getState) => {
            let result = await get({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/volume/claim`,
                params: { projectId, page, number },
                headers: authHeaders(),
                action: FETCH_STORAGES,
                dispatch,
            });
            return selectAllStorages(getState());
        },
        fetchStorage: (projectId, volumeSuffixId) => async (dispatch, getState) => {
            let result = await get({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/volume/claim/${volumeSuffixId}`,
                params: { projectId },
                headers: authHeaders(),
                action: FETCH_STORAGE,
                dispatch,
            });
            return selectStorage(getState(), volumeSuffixId);
        },
        createStorage: (projectId, body) => async (dispatch, getState) => {
            let result = await post({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: "/volume/claim",
                params: { projectId },
                headers: authHeaders(),
                action: CREATE_STORAGE,
                dispatch,
                body: body
            });
            // Uncomment if you want to redirect after creation
            // pushTo(mergeUrl(Urls.STORAGE_VIEW_STORAGE, { storageId: result.body.id }));
            return result.body;
        },
        updateStorage: (projectId, volumeSuffixId, currentStorageCapacity, currentDisplayName, body) => async (dispatch, getState) => {
            let result = await put({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/volume/claim/${volumeSuffixId}`,
                params: {
                    projectId,
                    storageCapacity: body.storageCapacity,
                    displayName: body.displayName
                },
                headers: authHeaders(),
                action: UPDATE_STORAGE,
                dispatch,
                body: body
            });
            return result.body;
        },
        deleteStorage: (projectId, volumeSuffixId) => async (dispatch, getState) => {
            let result = await del({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/volume/claim/${volumeSuffixId}`,
                params: { projectId },
                headers: authHeaders(),
                action: DELETE_STORAGE,
                dispatch
            });
            pushTo(Urls.STORAGE_STORAGES);
            return result.body;
        },
        fetchRegions: () => async (dispatch, getState) => {
            let result = await get({
                host: Hosts.EDGE_CLOUD_API,
                url: `/region/list`,
                headers: authHeaders(),
                action: FETCH_REGIONS,
                dispatch,
            });
            return result.body;
        },
        getDeploymentsUsingVolume: (projectId, volumeSuffixId) => async (dispatch, getState) => {
            let result = await get({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/volume/claim/${volumeSuffixId}/deployments`,
                params: { projectId },
                headers: authHeaders(),
                action: GET_DEPLOYMENTS_USING_VOLUME,
                dispatch,
            });
            return result.body;
        },
        getDeploymentVolumes: (projectId, shard, deploymentId) => async (dispatch, getState) => {
            let result = await get({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/deployment/${shard}/${deploymentId}/volumes`,
                params: { project_id: projectId },
                headers: authHeaders(),
                action: GET_DEPLOYMENT_VOLUMES,
                dispatch,
            });
            return result.body;
        },
        getStoragePrice: () => async (dispatch, getState) => {
            let storagePriceResponse = await get({
                host: Hosts.EDGE_CLOUD_API,
                url: `/charge/getStoragePrice`,
                headers: authHeaders(),
                action: GET_STORAGE_PRICE,
                dispatch,
            })

            return storagePriceResponse.body
        }
    },
    spec: {
        storages: {},
        // totalStoragePages: 0,
        regions: [],
        deploymentsUsingVolume: {},
    },
    modelReducer: (state, type, body, action) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        switch (type) {
            case FETCH_STORAGES:
            case GET_DEPLOYMENT_VOLUMES:
                return {
                    ...state,
                    storages: _.keyBy(body, 'Suffix'),
                    // totalStoragePages: body.totalPages
                };
            case FETCH_STORAGE:
                return {
                    ...state,
                    storages: {
                        ...state.storages,
                        ..._.keyBy(body, 'Suffix'),
                    },
                };
            case UPDATE_STORAGE:
                return {
                    ...state,
                    storages: {
                        ...state.storages,
                        [body.Suffix]: body,
                    },
                };
            case DELETE_STORAGE:
                const newStorages = { ...state.storages };
                delete newStorages[body.Suffix];
                return {
                    ...state,
                    storages: newStorages,
                };
            case FETCH_REGIONS:
                return {
                    ...state,
                    regions: body.regions
                };
            case GET_DEPLOYMENTS_USING_VOLUME:
                return {
                    ...state,
                    deploymentsUsingVolume: body,
                };
            default:
                return state;
        }
    }
}

export default AiStorage;
