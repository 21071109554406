import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UIState from "../../store/UIState";
import Ai from "../../store/models/Ai";
import { DeleteIcon, DocumentsIcon, OverviewIcon } from "../Svg";
import { Button } from "../Button";
import Loader from "../Loader";
import { Pagination } from "../Pagination";
import { FaCheckCircle, FaTimesCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { ModalTypes } from "../../constants";

export const KnowledgeTab = (props) => {
  const dispatch = useDispatch();
  const { chatbot } = props;
  const { projectId, id } = useParams();
  const [error, setError] = useState();
  const [crtPage, setCrtPage] = useState(1);
  const [documents, setDocuments] = useState();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchData()
  }, [chatbot.id])

  const fetchData = async (page = 0) => {
    try {
      setError()
      setLoading(true);
      let res = await dispatch(Ai.actions.fetchChatbotDocuments(id, projectId, page));
      setDocuments(_.get(res, 'documents'));
      setTotalPages(_.get(res, 'total_pages'));
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const handlePageChange = pageNumber => {
    setCrtPage(pageNumber);
    fetchData(pageNumber - 1);
  }

  const handleAddDocument = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_DOCUMENT, {
      chatbotId: id,
      projectId: projectId,
      onSuccess: (document) => {
        setDocuments([document, ...documents]);
      }
    }));
  };

  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage knowledge-base-tab'>
      {loading && <div className={'EmptyState'}>
        <Loader size='large' color='grey' />
      </div>}
      {!loading && documents && documents.length > 0 && <>
        <div className='RagChatbotDetailPage__header'>
          <Button
            className="RagChatbotDetailPage__add-button"
            color="green-outline"
            onClick={handleAddDocument}
            icon={<DocumentsIcon />}
            title="New Document"
          />
        </div>
        <div className='RagChatbotDetailPage__documents'>
          {documents.map(doc => <DocumentCard key={doc.id} doc={doc} fetchData={fetchData} crtPage={crtPage} />)}
        </div>
        <div className='RagChatbotDetailPage__pagination'>
          <Pagination
            size={'lg'}
            currentPage={crtPage}
            totalPages={totalPages}
            onPageChange={handlePageChange} />
        </div>
      </>}
      {!loading && documents && documents.length === 0 && <div className={'RagChatbotDetailPage__empty-state'}>
        No Documents
        <div className='RagChatbotDetailPage__header'>
          <Button
            className="RagChatbotDetailPage__add-button"
            color="green-outline"
            onClick={handleAddDocument}
            icon={<DocumentsIcon />}
            title="Add First Document"
          />
        </div>
      </div>
      }
    </div>
  </div>
}

const DocumentCard = (props) => {
  const { doc, fetchData, crtPage } = props;
  const { projectId, id } = useParams();
  const type = _.get(doc, 'metadata.type');
  const dispatch = useDispatch();
  const [currentDoc, setCurrentDoc] = useState(doc);
  const [isMetadataVisible, setIsMetadataVisible] = useState(false);
  const [editedMetadataJson, setEditedMetadataJson] = useState(() => {
    const { type, filename, text, ...editableMetadata } = doc.metadata;
    return JSON.stringify(editableMetadata, null, 2);
  });
  const [originalMetadataJson, setOriginalMetadataJson] = useState(() => {
    const { type, filename, text, ...editableMetadata } = doc.metadata;
    return JSON.stringify(editableMetadata, null, 2);
  });
  const [jsonError, setJsonError] = useState('');
  const [isOpening, setIsOpening] = useState(false);
  const metadataTextareaRef = useRef(null);

  useEffect(() => {
    if (isMetadataVisible && metadataTextareaRef.current) {
      metadataTextareaRef.current.focus();
    }
  }, [isMetadataVisible]);

  const handleDelete = () => {
    const onConfirm = async () => {
      await dispatch(Ai.actions.deleteChatbotDocument(projectId, id, doc.id));
      await fetchData(crtPage - 1);
    }
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: doc.id, onConfirm }));
  }

  const handleOpen = async () => {
    setIsOpening(true);
    try {
      const document = await dispatch(Ai.actions.fetchChatbotDocument(id, projectId, doc.id));
      const content = document.content;
      const fileName = type === 'file' ? _.get(doc, 'metadata.filename', 'document.txt') : 'document.txt';
      const blob = new Blob([content], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url, '_blank');
      newWindow.document.title = fileName;
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to open document:', error);
      // Optionally, show an error message to the user
    } finally {
      setIsOpening(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let updatedMetadata;
      try {
        const editedMetadata = JSON.parse(editedMetadataJson);

        updatedMetadata = {
          ...editedMetadata,
          type: currentDoc.metadata.type,
          ...(currentDoc.metadata.filename && { filename: currentDoc.metadata.filename }),
          ...(currentDoc.metadata.text && { text: currentDoc.metadata.text })
        };

        setJsonError('');
      } catch (err) {
        setJsonError('Invalid JSON format');
        return;
      }

      await dispatch(Ai.actions.updateChatbotDocument(id, doc.id, {
        project_id: projectId,
        metadata: JSON.stringify(updatedMetadata)
      }));
      setCurrentDoc(d => ({ ...d, metadata: updatedMetadata }));
      setOriginalMetadataJson(editedMetadataJson);
      toast.success('Metadata updated successfully');
    } catch (e) {
      toast.error('Failed to update metadata')
      setEditedMetadataJson(originalMetadataJson);
    }
  }

  const handleCancel = () => {
    setEditedMetadataJson(originalMetadataJson);
    setJsonError('');
  }

  const hasChanges = editedMetadataJson !== originalMetadataJson;
  const lastUpdated = _.get(doc, 'ui.ts') ? new Date(_.get(doc, 'ui.ts')).toLocaleString() : '-';

  return (
    <div className='KnowledgeDocument' key={doc.id}>
      <div className='KnowledgeDocument__main'>
        <div className='KnowledgeDocument__info'>
          {type === 'file' && (
            <div className='KnowledgeDocument__property'>
              <span className='property-label'>File name:</span>
              <span className='property-value'>{_.get(doc, 'metadata.filename')}</span>
            </div>
          )}
          <div className='KnowledgeDocument__property'>
            <span className='property-label'>Document ID:</span>
            <span className='property-value'>{doc.id}</span>
          </div>
          <div className='KnowledgeDocument__property'>
            <span className='property-label'>Uploaded by:</span>
            <span className='property-value'>{_.get(doc, 'ui.user') ?? 'External'}</span>
          </div>
          <div className='KnowledgeDocument__property'>
            <span className='property-label'>Last updated:</span>
            <span className='property-value'>{lastUpdated}</span>
          </div>

          {_.get(doc, 'metadata.description') && (
            <div className='KnowledgeDocument__property'>
              <span className='property-label'>Description:</span>
              <span className='property-value'>{_.get(doc, 'metadata.description')}</span>
            </div>
          )}
        </div>

        <div className='KnowledgeDocument__actions'>
          <Button
            className="KnowledgeDocument__button delete Button--icon-only"
            onClick={handleDelete}
            color="red-outline"
            size="small"
            icon={<DeleteIcon />}
          />
          <Button
            className="KnowledgeDocument__button"
            onClick={handleOpen}
            color="green-outline"
            title={isOpening ? "" : "Open"}
            size="small"
            icon={isOpening ? <Loader size="small" color="green" /> : <OverviewIcon />}
            disabled={isOpening}
          >
            Open
          </Button>
        </div>
      </div>

      <Button
        className={`KnowledgeDocument__button KnowledgeDocument__metadata-toggle reverse ${isMetadataVisible ? 'active' : ''}`}
        onClick={() => setIsMetadataVisible(!isMetadataVisible)}
        color="grey-outline"
        size="small"
        icon={isMetadataVisible ? <FaChevronUp /> : <FaChevronDown />}
        title={"Edit Metadata"} />

      {isMetadataVisible && (
        <div className='KnowledgeDocument__metadata'>
          <div className='KnowledgeDocument__metadata-content'>
            <textarea
              ref={metadataTextareaRef}
              value={editedMetadataJson}
              onChange={(e) => setEditedMetadataJson(e.target.value)}
              style={{ fontFamily: 'monospace' }}
            />
            {jsonError && (
              <div className='KnowledgeDocument__error'>
                {jsonError}
              </div>
            )}
            {hasChanges && (
              <div className='KnowledgeDocument__metadata-actions'>
                <div className='KnowledgeDocument__metadata-actions--icon cancel' onClick={handleCancel} title="Cancel"><FaTimesCircle /></div>
                <div className='KnowledgeDocument__metadata-actions--icon submit' onClick={handleSubmit} title="Submit"><FaCheckCircle /></div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};