import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { EllipsisIcon, CopyIcon, BetaIcon } from "../../../components/Svg";
import { ModalTypes, Urls } from '../../../constants';
import UIState from '../../../store/UIState';
import Ai, { getRagChatbotIframeUrl, selectAllRagChatbots, selectAllVMs } from '../../../store/models/Ai';
import { getLinkByDeployment, getVmResourcesString } from '../../../utils';
import { getStatusSum, checkActivePod, age, checkDeploymentsReady } from '../../../utils';
import classNames from 'classnames';
import { Button } from '../../../components/Button';
import Loader from '../../../components/Loader';
import { selectCurrentUserId } from '../../../store/models/User';
import { selectCurrentProjectId } from '../../../store/models/Project';
import _ from "lodash";
import { FaCheckCircle, FaEdit, FaTimesCircle } from "react-icons/fa";
import { selectAppStarted } from '../../../store/AppState';
import ApiKeyNote from '../../../components/ApiKeyNote';
import Tooltip from "../../../components/Tooltip";
import { mergeUrl } from "../../../utils/url";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";


function RAGChatbotPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loadingPlayground, setLoadingPlayground] = useState(null);
  const [error, setError] = useState();
  const appStarted = useSelector(state => selectAppStarted(state));
  const userId = useSelector(state => selectCurrentUserId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const chatbots = useSelector(state => selectAllRagChatbots(state, projectId));
  const vms = useSelector(state => selectAllVMs(state));
  const hasNoChatbot = (!_.isNil(chatbots) && _.isEmpty(chatbots));
  const [activePopup, setActivePopup] = useState(-1);
  const history = useHistory();
  let timeoutId;

  async function fetchData() {
    try {
      if (_.isEmpty(chatbots)) setLoading(true);
      setError();
      await dispatch(Ai.actions.fetchRagChatbots(projectId));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError();
    }
  }

  useEffect(() => {
    if (!appStarted || _.isNil(userId)) return;
    fetchData();
    return () => { clearTimeout(timeoutId) };
  }, [projectId, Object.keys(chatbots).length]);

  useEffect(() => {
    (async () => {
      if (!appStarted) return;
      try {
        await dispatch(Ai.actions.fetchLlmEndpints(projectId));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [projectId])


  const handleOpenModel = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_RAG_CHATBOT))
  }

  const handlePopup = (e, i) => {
    e.stopPropagation();
    e.preventDefault();
    if (i === -1) {
      setActivePopup(i)
      return;
    }
    setActivePopup(v => {
      if (v === i) return -1;
      return i;
    })
  }

  const handleDelete = (chatbot) => {
    const onConfirm = async () => {
      await dispatch(Ai.actions.deleteRagChatbot(projectId, chatbot.id));
      await dispatch(Ai.actions.fetchRagChatbots(projectId));
    }
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: chatbot.name || chatbot.id, onConfirm }));
    setActivePopup(-1);
  }


  const navToDetail = (id) => {
    history.push(mergeUrl(Urls.AI_RAG_CHATBOT_DETAIL, { projectId, id }));
  }

  const onOpenPlayground = async (e, chatbot) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      setLoadingPlayground(chatbot.id);
      const accessToken = await dispatch(Ai.actions.fetchRagAccessToken(projectId, chatbot.id));
      const iframeUrl = getRagChatbotIframeUrl(chatbot, accessToken);
      window.open(iframeUrl, '_blank');
    } catch (e) {
      console.error(e);
      toast.error('Failed to open playground, please try again later');
    } finally {
      setLoadingPlayground(null);
    }
  }

  const onDuplicate = async (chatbot) => {
    setIsDuplicating(true);
    await dispatch(Ai.actions.duplicateRagChatbot(projectId, chatbot.id));
    await dispatch(Ai.actions.fetchRagChatbots(projectId));
    toast.success('Chatbot duplicated successfully');
    setIsDuplicating(false);
    setActivePopup(-1);
  }

  return (<div className={'AiServicePage'}>
    <div className={'AiServicePage__header'}>
      <div className={'AiServicePage__header--title'}>
        <div className={'PageTitleBar__titleWithIcon'}>RAG Chatbot <BetaIcon /></div>
        {/* <ApiKeyNote type={'GPU nodes'} /> */}
      </div>
      <div className={'AiServicePage__header--button'} onClick={handleOpenModel}>
        <div className={'AiServiceHeaderButtonIcon empty'}>
        </div>
        New RAG Chatbot
      </div>
    </div>
    <div className={'AiServicePage__content'}>
      {loading &&
        <div className={'EmptyState'}>
          <Loader size={'large'} color={'green'} />
        </div>}
      {!hasNoChatbot && !loading && !error && <div className={'AiServicePage__table'}>
        <div className={'AiServicePage__table--header'}>
          <div className={'AiServicePage__table--header-row'}>
            <div className={'AiServicePage__table--cell chatbot-title'}>Name</div>
            <div className={'AiServicePage__table--cell chatbot-id'}>ID</div>
            <div className={'AiServicePage__table--cell view'}>Playground</div>
            <div className={'AiServicePage__table--cell view'}>Details</div>
            <div className={'AiServicePage__table--cell duration'}>Duration</div>
            <div className={'AiServicePage__table--cell ellipsis'}></div>
          </div>
        </div>
        <div className={'AiServicePage__table--body'}>
          {Object.keys(chatbots).map((k, i) => {
            const chatbot = chatbots[k];
            return (
              <div className={'AiServicePage__table--row clickable'} key={i} onClick={() => navToDetail(chatbot.id)}>
                <div className={'AiServicePage__table--cell chatbot-title'}>
                  <NameSection chatbot={chatbot} />
                </div>
                <div className={'AiServicePage__table--cell chatbot-id'}>
                  {chatbot.id}
                </div>
                <div className={classNames('AiServicePage__table--cell view')}>
                  {loadingPlayground === chatbot.id
                    ? <Loader size={'small'} color={'green'} />
                    : <div className={classNames('AiServicePageViewButton', { active: true })}
                      onClick={(e) => onOpenPlayground(e, chatbot)}>
                      Open
                    </div>}
                </div>
                <div className={classNames('AiServicePage__table--cell view')} onClick={() => navToDetail(chatbot.id)}>
                  <div className={classNames('AiServicePageViewButton', { active: true })}>
                    Details
                  </div>
                </div>
                <div className={'AiServicePage__table--cell duration'}>
                  {age(chatbot.created_at)}
                </div>
                <div className={'AiServicePage__table--cell ellipsis'}>
                  <div className={'AiServicePageEllipsis'} onClick={(e) => handlePopup(e, i)}>
                    <EllipsisIcon />
                  </div>
                  {activePopup === i && <div className={'AiServicePageEllipsis__popup'} onClick={(e) => { e.stopPropagation() }}>
                  <div className={'AiServicePageEllipsis__popup--row'} onClick={() => onDuplicate(chatbot)}>{isDuplicating ? <Loader size={'small'} color={'green'} /> : 'Duplicate'}</div>
                  <div className={'AiServicePageEllipsis__popup--row'} onClick={() => handleDelete(chatbot)}>Delete</div>
                  </div>}
                </div>
              </div>
            )
          })}
        </div>
      </div>}
      {hasNoChatbot && !loading && !error &&
        <div className={'EmptyState'}>
          <div className={'EmptyState__title'}>No RAG Chatbot</div>
          <div className={'EmptyState__subtitle'}>Create your first RAG Chatbot to get started</div>
          <Button size={'large'}
            title={'New RAG Chatbot'}
            onClick={handleOpenModel}
          />
        </div>}
      {!loading && error && <div className={"AiServicePage__error"}>
        {error}
        <Button onClick={fetchData}
          color={"green-outline"}
          title={"Retry"} />
      </div>}
    </div>
  </div>
  );
}

const NameSection = ({ chatbot }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const nameRef = useRef();
  const handleEdit = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  }
  const handleSubmit = async (e) => {
    e.stopPropagation();
    let name = nameRef.current.value;
    let projectId = chatbot.project_id;
    await dispatch(Ai.actions.updateRagChatbotName(projectId, chatbot.id, name));
    await dispatch(Ai.actions.fetchRagChatbots(projectId));
    setIsEditing(false);
  }
  const handleCancel = (e) => {
    e.stopPropagation();
    setIsEditing(false);
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  const onRowClick = (e) => {
    if (isEditing) e.stopPropagation();
  }

  return <div className='AiServicePage__name-warp' onClick={onRowClick}>
    {!isEditing ? <div className='AiServicePage__name-warp--label'>
      {chatbot.name ?
        (chatbot.name.length > 30 ? chatbot.name.slice(0, 30) + '...' : chatbot.name) : `\u00A0`}
    </div> :
      <input type='text' ref={nameRef} defaultValue={chatbot.name} onKeyDown={handleKeyDown} />}
    {isEditing ?
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon cancel' onClick={handleCancel} title="Cancel"><FaTimesCircle /></div>
        <div className='PageTitleBar__right--icon submit' onClick={handleSubmit} title="Submit"><FaCheckCircle /></div>
      </div> :
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon edit' onClick={handleEdit}><FaEdit /></div>
      </div>}
  </div>
}

export default RAGChatbotPage;