import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { BackArrowIcon, DeleteIcon, ReconfigIcon, StartIcon, StopIcon, WarningIcon } from "../../../components/Svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Features, Urls } from "../../../constants";
import { mergeUrl } from "../../../utils/url";
import { AiTabs, AiTab } from '../../../components/AiTabs';
import Ai, { selectDeployment, selectAllVMs } from '../../../store/models/Ai';
import { getLinkByDeployment, getStatus, getHardwareDetail, getPodId, getStatusSum, formatDate, checkActivePod, checkDeploymentsReady } from '../../../utils';
import classNames from 'classnames';
import Loader from '../../../components/Loader';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Button } from '../../../components/Button';
import { authHeaders } from "../../../utils/fetch";
import { Hosts, ModalTypes } from "../../../constants";
import AiStorage, { getFormateStorageSize, selectAllStorages } from '../../../store/models/AiStorage';
import { selectIsFeatureEnabled } from '../../../store/models/Organization';
import { MdOutlineNotStarted, MdOutlineStopCircle, MdDeleteOutline } from "react-icons/md";
import { GrDocumentConfig } from "react-icons/gr";
import UIState from '../../../store/UIState';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import User from '../../../store/models/User';


function GpuNodeDetailPage() {
  const dispatch = useDispatch();
  const { projectId, shard, suffix, id } = useParams();
  const history = useHistory();
  const logRef = useRef("")
  const [logs, setLogs] = useState("")
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const deployment = useSelector(state => selectDeployment(state, projectId, suffix || id));
  const volumes = useSelector(state => selectAllStorages(state));

  const link = deployment ? getLinkByDeployment(deployment) : '';
  const httpPort = _.get(deployment, 'ContainerPort');
  const portKey = _.get(deployment, 'AdditionalPorts.0');
  const mapPort = _.get(deployment, `PortMappings.${portKey}`);
  const sshString = `ssh -i ~/.ssh/id_rsa -p ${mapPort} root@${_.get(deployment, 'NodePublicIP')}`;
  const vms = useSelector(state => selectAllVMs(state));
  const isStorageEnabled = useSelector(state => selectIsFeatureEnabled(state, Features.STORAGE));
  const isStopped = !!id;
  let userId = isStopped ? _.get(deployment, 'UserID') : _.get(deployment, 'UserID');
  let hasVolume = isStopped ? deployment?.volumes?.length > 0 : deployment?.Volumes?.length > 0;
  let curVolumes = isStopped ? _.get(deployment, 'volumes') : _.get(deployment, 'Volumes')
  if (!userId || userId.startsWith('prj')) userId = null;
  else userId = 'usr_' + userId;
  const [userName, setUserName] = useState();
  let timeoutId;

  useEffect(() => {
    let loop;
    let retry;

    const streamLogs = async () => {
      let response;
      let url = Hosts.EDGE_CLOUD_CONTROLLER_API + `/deployment/${shard}/${suffix}/logs?follow=true&project_id=${projectId}`
      try {
        response = await fetch(url, { headers: authHeaders() });
        if (response.status !== 200) {
          retry = setTimeout(() => streamLogs(), 10 * 1000);
          return;
        }
        const reader = response.body.getReader();

        const read = async () => {
          const { done, value } = await reader.read();
          logRef.current = logRef.current.concat(new TextDecoder().decode(value))
          if (done || isStopped) {
            setLogs(logRef.current)
            clearTimeout(loop)
            streamLogs()
            return;
          }
          setLogs(logRef.current)
          loop = setTimeout(() => {
            read().then().catch(err => {
              logRef.current = ""
              setLogs(logRef.current)
              retry = setTimeout(() => streamLogs(), 10);
            })
          }, 10)
        }

        read().then().catch(err => {
          console.log(err)
          logRef.current = ""
          setLogs(logRef.current)
          retry = setTimeout(() => streamLogs(), 10);
        })

      } catch (err) {
        if (typeof err === 'string') {
          console.log("URL is unreachable: ", url, err);
          return;
        }
      }
    }

    (async () => {
      let force = (getStatus(_.get(deployment, 'PodPhase')) === 'stopped' || getStatus(_.get(deployment, 'PodPhase')) === 'Running') ? false : true;
      // console.log('force:', force)
      // console.log("(getStatus(_.get(deployment, 'PodPhase')) === 'stopped' || getStatus(_.get(deployment, 'PodPhase')) === 'Running'):", (getStatus(_.get(deployment, 'PodPhase')) === 'stopped' || getStatus(_.get(deployment, 'PodPhase')) === 'Running'))
      // console.log('getStatus(_.get(deployment, "PodPhase")):', getStatus(_.get(deployment, "PodPhase")))
      // console.log('deployment.PodPhase:', _.get(deployment, 'PodPhase'))
      await fetchDataLoop(force);
      if (!isStopped) streamLogs();
    })();

    return () => {
      clearTimeout(loop);
      clearTimeout(retry);
    };

  }, [projectId, shard, suffix, isStopped]);

  useEffect(() => {
    async function fetchUser() {
      if (!userId || isStopped) return;
      let userRes = await dispatch(User.actions.fetchUser(userId));
      setUserName(`${_.get(userRes, 'first_name') || ''} ${_.get(userRes, 'last_name') || ''}`)
    }
    fetchUser();
  }, [userId, isStopped])

  const fetchData = async (force) => {
    if (!force) setLoading(true);
    if (error) setError();
    try {
      if (id) {
        await dispatch(Ai.actions.fetchDeploymentBaseDetail(projectId, id));
      } else {
        await dispatch(Ai.actions.fetchDeploymentDetail(projectId, shard, suffix));
        await dispatch(AiStorage.actions.getDeploymentVolumes(projectId, shard, suffix));
        const eventsRres = await dispatch(Ai.actions.fetchDeploymentEvents(projectId, shard, suffix));
        setEvents(eventsRres);
      }
      if (Object.keys(volumes).length === 0) {
        await dispatch(AiStorage.actions.fetchStorages(projectId));
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  async function fetchDataLoop(force) {
    try {
      await fetchData(force);
      if (force || !checkDeploymentsReady([deployment])) {
        timeoutId = setTimeout(() => fetchDataLoop(force), 1000 * 5)
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    return () => { clearTimeout(timeoutId) };
  }, [])


  const [selectedSubTab, setSelectedSubTab] = useState('details');

  const handleDelete = () => {
    const onConfirm = async () => {
      try {
        setLoading(true);
        if (deployment.Suffix) {
          await dispatch(Ai.actions.deleteDeployment(projectId, deployment.Shard, deployment.Suffix));
        } else {
          await dispatch(Ai.actions.deleteDeploymentBase(projectId, deployment.id));
        }
        history.push(mergeUrl(Urls.AI_GPU_NODE, { projectId }));
        toast.success(`The deployment has been deleted.`)
      } catch (e) {
        toast.error(`Failed to delete the deployment. Error: ${e.message}`)
        setLoading(false);
      }
    }
    let title = isStopped ? deployment.name : deployment.Name;
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title, onConfirm }));
  }

  const handleReconfigure = () => {
    let url = mergeUrl(Urls.AI_GPU_NODE_UPDATE, { projectId, id: deployment.id })
    history.push(url + '?t=detail');
  }

  const handleStart = () => {
    const onConfirm = async () => {
      try {
        setLoading(true);
        let res = await dispatch(Ai.actions.startDeployment(projectId, deployment.id));
        setTimeout(() => {
          history.push(mergeUrl(Urls.AI_GPU_NODE_DETAIL, { projectId, suffix: _.get(res, 'Suffix'), shard: _.get(res, 'Shard') }));
        }, 2000)
        toast.success(`The deployment has been started.`)
      } catch (e) {
        toast.error(`Failed to start the deployment. Error: ${e.message}`);
        setLoading(false);
      }
    }
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: "Start deployment",
      message: "Are you sure you want to start the deployment?",
      onConfirm: onConfirm,
      confirmLabel: "Yes",
      cancelLabel: "No",
      size: 's'
    }));
  }

  const handleStop = () => {
    const onConfirm = async () => {
      try {
        setLoading(true);
        let res = await dispatch(Ai.actions.stopDeployment(projectId, deployment.Shard, deployment.Suffix));
        history.push(mergeUrl(Urls.AI_GPU_NODE_DETAIL_BASE, { projectId, id: _.get(res, 'ID') }));
        toast.success(`The deployment has been stopped.`)
      } catch (e) {
        toast.error(`Failed to stop the deployment. Error: ${e.message}`);
        setLoading(false);
      }
    }
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 10 }}><WarningIcon /></div>
        Stop deployment
      </div>,
      message: <div style={{ padding: 10 }}>
        Data stored in ephemeral storage will be lost when a GPU node is stopped. To ensure data preservation, we recommend attaching a persistent volume to your GPU node during its creation. Persistent volumes safely retain data even when the node is stopped.
      </div>,
      onConfirm: onConfirm,
      confirmLabel: "Yes",
      cancelLabel: "No",
      size: 's'
    }));
  }

  return (<div className={'AiServicePage AiServicePage--with-back-button'}>
    <Link to={mergeUrl(Urls.AI_GPU_NODE, { projectId })}>
      <div className={'AiServicePage__back-button gpu-node'}>
        <BackArrowIcon />
        <div className={'AiServicePage__back-button--label'}>
          GPU Node
        </div>
      </div>
    </Link>
    {!loading && deployment && <div className={'AiServicePage__header detail'}>
      <div className={'AiServicePage__header--title'}>{deployment.Name || deployment.name || deployment.id}</div>
      {httpPort > 0 && <div className={classNames(`AiServicePage__header--button detail`, { 'disabled': !checkActivePod(deployment) })}>
        {checkActivePod(deployment) ? <a href={link} target='_blank' >
          HTTP Endpoint
        </a> : <>
          <Loader size='small' color='grey' />
          HTTP Endpoint
        </>}
      </div>}
    </div>}
    {!loading && deployment && <div className={'AiServicePage__action-bar'}>
      {!isStopped && <div className={'AiServicePage__action-button stop'} onClick={handleStop}>
        <StopIcon />
        Stop
      </div>}
      {isStopped && <div className={'AiServicePage__action-button start'} onClick={handleStart}>
        <StartIcon />
        Start
      </div>}
      <div className={'AiServicePage__action-button delete'} onClick={handleDelete}>
        <DeleteIcon />
        Delete
      </div>
      {isStopped && <div className={'AiServicePage__action-button reconfig'} onClick={handleReconfigure}>
        <ReconfigIcon />
        Reconfig
      </div>}
    </div>
    }
    {!loading && deployment && <div className={'AiServicePage__content new-deployment'}>
      <AiTabs>
        <AiTab isSelected={(selectedSubTab === 'details')}
          onClick={() => {
            setSelectedSubTab('details')
          }}>
          Details
        </AiTab>
        {!isStopped && <AiTab isSelected={(selectedSubTab === 'nodes')}
          onClick={() => {
            setSelectedSubTab('nodes')
          }}>
          Nodes
        </AiTab>}
        {/* <AiTab isSelected={(selectedSubTab === 'stats')}
          onClick={() => {
            setSelectedSubTab('stats')
          }}>
          Stats
        </AiTab> */}
        {!isStopped && <AiTab isSelected={(selectedSubTab === 'logs')}
          onClick={() => {
            setSelectedSubTab('logs')
          }}>
          Logs
        </AiTab>}
        {!isStopped && <AiTab isSelected={(selectedSubTab === 'events')}
          onClick={() => {
            setSelectedSubTab('events')
          }}>
          Events
        </AiTab>}
      </AiTabs>
      {
        (selectedSubTab === 'details') && deployment &&
        <div className='AiServicePage__tab-content'>
          <div className='ModelDeploymentDetailPage__details'>
            <div className='ModelDeploymentDetailPage__details--row section'>
              <div className='ModelDeploymentDetailPage__details--section-title'>
                STATUS
              </div>
            </div>
            {isStopped && <>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Deployment ID
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {deployment.name}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Name
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {_.get(deployment, 'annotations.nickname')}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Status
                </div>
                <div className='ModelDeploymentDetailPage__details--value status'>
                  {getStatusSum(deployment.PodPhase, deployment.Endpoint)}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Created
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {formatDate(deployment.create_time)}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Region
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {deployment.selected_region || 'N/A'}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Price
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {`$0`}
                </div>
              </div>
            </>}
            {!isStopped && <>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Deployment Key
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {deployment.Suffix}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Deployment ID
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {deployment.Name}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Name
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {_.get(deployment, 'Annotations.nickname')}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  SSH
                </div>
                <div className={classNames('ModelDeploymentDetailPage__details--value')}>
                  {sshString}
                </div>
              </div>
              {httpPort > 0 && <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  HTTP Endpoint
                </div>
                <div className={classNames('ModelDeploymentDetailPage__details--value green',
                  { disabled: !checkActivePod(deployment) })}>
                  {checkActivePod(deployment) ? <a href={link} target='_blank' >
                    {link}
                  </a> : link}
                </div>
              </div>}
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Status
                </div>
                <div className='ModelDeploymentDetailPage__details--value status'>
                  {getStatusSum(deployment.PodPhase, deployment.Endpoint)}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Replicas
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {deployment.Replicas}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Available Replicas
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {deployment.Replicas}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Created
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {formatDate(deployment.CreatedAt)}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Region
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {deployment.Region}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Price
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {`$${(vms[deployment.MachineType].price_hour) / 100} per hour`}
                </div>
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Creator User ID
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {userId}
                </div>
              </div>
              {userName && <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Creator User Name
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  {userName}
                </div>
              </div>}
            </>}
            <div className='ModelDeploymentDetailPage__details--row section'>
              <div className='ModelDeploymentDetailPage__details--section-title'>
                METADATA
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Image
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {isStopped ? deployment.container_image : deployment.ImageURL}
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Hardware
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {getHardwareDetail(vms, (isStopped ? deployment.vm_id : deployment.MachineType))}
              </div>
            </div>

            {isStorageEnabled && <>
              <div className='ModelDeploymentDetailPage__details--row section'>
                <div className='ModelDeploymentDetailPage__details--section-title'>
                  STORAGE
                </div>
              </div>

              <div className='ModelDeploymentDetailPage__storage'>
                <div className='ModelDeploymentDetailPage__storage--header'>
                  <div className='ModelDeploymentDetailPage__storage--cell'>Name</div>
                  <div className='ModelDeploymentDetailPage__storage--cell'>ID</div>
                  <div className='ModelDeploymentDetailPage__storage--cell'>Mounted at</div>
                  <div className='ModelDeploymentDetailPage__storage--cell'>Volume size</div>
                  {/* <div className='ModelDeploymentDetailPage__storage--cell'>Cost</div> */}
                </div>
                {hasVolume ? (
                  curVolumes.map((storage, i) => {
                    const storageDetail = _.find(volumes, volume => volume.Suffix === storage.volumeId);
                    return (
                      <div key={i} className='ModelDeploymentDetailPage__storage--row'>
                        <div className='ModelDeploymentDetailPage__storage--cell'>{storageDetail?.DisplayName}</div>
                        <div className='ModelDeploymentDetailPage__storage--cell'>{storageDetail?.Name}</div>
                        <div className='ModelDeploymentDetailPage__storage--cell'>{storage.mountPath || '-'}</div>
                        <div className='ModelDeploymentDetailPage__storage--cell'>{getFormateStorageSize(storageDetail)}</div>
                        {/* <div className='ModelDeploymentDetailPage__storage--cell'>${storageDetail?.Cost || '-'} per hour</div> */}
                      </div>
                    )
                  })
                ) : (
                  <div className='ModelDeploymentDetailPage__storage--row empty'>
                    <div className='ModelDeploymentDetailPage__storage--cell' colSpan="5">No persistent volumes attached</div>
                  </div>
                )}
              </div>
            </>}
          </div>
        </div>
      }
      {
        (selectedSubTab === 'nodes') && deployment &&
        <div className='AiServicePage__tab-content'>
          {deployment.PodPhase.split('; ').map((podStr, i) => {
            return (< div className='ModelDeploymentDetailPage__nodes' key={i}>
              <div className='ModelDeploymentDetailPage__nodes--row'>
                <div className='ModelDeploymentDetailPage__nodes--cell id'>ID</div>
                {/* <div className='ModelDeploymentDetailPage__nodes--cell specs'>Specs</div> */}
                <div className='ModelDeploymentDetailPage__nodes--cell status'>Status</div>
              </div>
              <div className='ModelDeploymentDetailPage__nodes--row'>
                <div className='ModelDeploymentDetailPage__nodes--cell id'>{getPodId(podStr)}</div>
                {/* <div className='ModelDeploymentDetailPage__nodes--cell specs'>2398472938742</div> */}
                <div className='ModelDeploymentDetailPage__nodes--cell status'>{getStatus(podStr)}</div>
              </div>
            </div>)
          })}
        </div>
      }
      {
        (selectedSubTab === 'stats') &&
        <div className='AiServicePage__tab-content'>
          stats
        </div>
      }
      {
        (selectedSubTab === 'logs') &&
        <div className='AiServicePage__tab-content'>
          {(logs && logs.length > 0) ? <div className='AiServicePage__code-container'>
            <SyntaxHighlighter
              lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
              language="javascript"
              style={vscDarkPlus}>
              {logs}
            </SyntaxHighlighter>
          </div> : <div className='EmptyState model-detail'>
            <div className={'EmptyState__title'}>No logs available yet.</div>
          </div>}
        </div>
      }
      {
        (selectedSubTab === 'events') &&
        <div className='AiServicePage__tab-content'>
          {events && events.map((evt, i) => {
            return <div className='AiServicePage__event' key={i}>
              {Object.keys(evt).map((k, i) => {
                return <div className='AiServicePage__event--info' key={i}>
                  <div className={'AiServicePage__event--label'}>{k}:</div>
                  <div className={classNames('AiServicePage__event--value', { 'color-red': k === 'Reason' })}>
                    {k === 'Time' ? formatDate(evt[k]) : evt[k]}
                  </div>
                </div>
              })}
            </div>
          })}
          {(!events || events.length === 0) && <div className='AiServicePage__event--empty-state'>
            No Events
          </div>}
        </div>
      }
    </div>}
    {loading && <div className={'EmptyState'}>
      <Loader size='large' color='grey' />
    </div>}
    {!loading && error && !deployment && <div className={"AiServicePage__error"}>
      {error}
      <Button onClick={fetchData}
        color={"green-outline"}
        title={"Retry"} />
    </div>}
  </div >
  );
}

export default GpuNodeDetailPage;
