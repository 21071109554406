import { Hosts } from "../../constants";
import { authHeaders, post } from "../../utils/fetch";

const GENERATE_PRESIGNED_URL = 'GENERATE_PRESIGNED_URL';
const UPLOAD_FILE = 'UPLOAD_FILE';

export const Upload = {
    actions: {

        uploadFile: (projectId, filename, file) => async (dispatch, getState) => {
            const presignedResponse = await dispatch(Upload.actions.generatePresignedUrl(projectId, filename));
            const presignedUrl = presignedResponse?.presigned_url;
            await dispatch(Upload.actions.uploadToGcp(presignedUrl, file));
            return presignedResponse.file_url;
        },

        generatePresignedUrl: (projectId, filename) => async (dispatch, getState) => {
            const response = await post({
                host: Hosts.EDGE_CLOUD_API,
                url: "/project/:projectId/presigned_url/:filename",
                params: {
                    projectId,
                    filename
                },
                headers: authHeaders(),
                dispatch,
                action: GENERATE_PRESIGNED_URL
            });
            return response?.body;
        },

        uploadToGcp: (url, file) => async (dispatch, getState) => {
            let dataUrl = await readFile(file)
            let fileRequest = await fetch(dataUrl)
            let fileBlob = await fileRequest.blob()

            return fetch(url, {
                method: 'PUT',
                body: fileBlob,
                headers: { 'Content-Type': 'application/octet-stream' },
            })
        }

    }
}


/** HELPERS */

const readFile = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}