import { useEffect, useState } from "react";
import { getRagChatbotIframeUrl } from "../../store/models/Ai";
import { toast } from "react-toastify";
import cx from "classnames";
import { Button } from "../Button";
import { Hosts } from "../../constants";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";

// Add this custom style object
const customStyle = {
  ...vscDarkPlus,
  'pre[class*="language-"]': {
    ...vscDarkPlus['pre[class*="language-"]'],
    background: 'rgba(24, 201, 157, 0.1)',
    padding: '10px 15px',
    borderRadius: '6px',
    margin: '0'
  },
  'code[class*="language-"]': {
    ...vscDarkPlus['code[class*="language-"]'],
    color: '#18C99D'
  },
  'code[class*="language-javascript"]': {
    color: '#18C99D'
  },
  'code[class*="language-html"]': {
    color: '#18C99D'
  },
  'code[class*="language-text"]': {
    color: '#18C99D'
  },
  '.token': {
    color: '#18C99D'
  }
};

const WidgetExtra = () => {
    return (
        <div className="RagChatbotDetailPage__widget-extra">
            <h4>
                Copy the code and paste it into your index.html file
            </h4>
            <div className="RagChatbotDetailPage__widget-extra--command">
                To apply the widget to all pages of your website, simply remove the "pages" parameter from the configuration.
            </div>
            <div className="RagChatbotDetailPage__widget-extra--command">
                To specify pages, use complete URLs: https://mywebsite.com/page1
            </div>
            <div className="RagChatbotDetailPage__widget-extra--command">
                Use wildcards to match multiple pages: https://mywebsite.com/blog/* matches all blog pages
            </div>
        </div>
    )
}

const DiscordExtra = ({apiUrl, accessToken}) => {
    return (
        <div className="RagChatbotDetailPage__widget-extra">
            <h4>
                Run the following commands to in the Discord channel to setup your bot
            </h4>
                <div className="RagChatbotDetailPage__widget-extra--command">Set your chatbot API endpoint URL*</div>
                    <SyntaxHighlighter style={customStyle}>
                        {`/chatbot api_url ${apiUrl}`}
                </SyntaxHighlighter>
                <div className="RagChatbotDetailPage__widget-extra--command">Set your chatbot API access token*</div>
                    <SyntaxHighlighter style={customStyle}>
                        {`/chatbot access_token ${accessToken}`}
                    </SyntaxHighlighter>
                <div className="RagChatbotDetailPage__widget-extra--command">Enable the bot on the current Discord channel*</div>
                    <SyntaxHighlighter style={customStyle}>
                        {`/chatbot enable True`}
                    </SyntaxHighlighter>
                <div className="RagChatbotDetailPage__widget-extra--command">Change the bot's display name</div>
                    <SyntaxHighlighter style={customStyle}>
                        {`/chatbot name [my_chatbot_name]`}
                    </SyntaxHighlighter>
                <div className="RagChatbotDetailPage__widget-extra--command">Set a custom avatar URL</div>
                    <SyntaxHighlighter style={customStyle}>
                        {`/chatbot avatar_url [my_avatar_url]`}
                    </SyntaxHighlighter>

                <div className="RagChatbotDetailPage__widget-extra--note">* Required</div>
        </div>
    )
}

const TAB_CONFIG = {
    curl: {
        label: 'cURL',
        language: 'javascript',
        title: 'cURL code',
        button: 'copy'
    },
    iframe: {
        label: 'iFrame embed',
        language: 'html',
        title: 'iFrame embed',
        button: 'copy',
        description: 'Copy the code and paste it into your HTML file.'
    },
    standalone: {
        label: 'Standalone page',
        language: 'html',
        title: 'Standalone URL',
        button: 'open',
        description: 'Personalize the standalone page by going to the Settings tab.'
    },
    widget: {
        label: 'Widget',
        language: 'javascript',
        title: 'Widget script',
        button: 'copy',
        extra: <WidgetExtra />
    },
    discord: {
        label: 'Discord',
        language: 'text',
        title: 'Discord Bot Integration',
        button: 'open',
        spotlight: true,
        extra: ({apiUrl, accessToken}) => <DiscordExtra apiUrl={apiUrl} accessToken={accessToken} />
    }
};

export const RagIntegrationTab = (props) => {
    const { chatbot, accessToken } = props;
    const [tab, setTab] = useState('curl');

    return (
        <div className='AiServicePage__tab-content'>
            <div className='RagChatbotDetailPage integration'>
                <div className='RagChatbotDetailPage__tabs'>
                    {Object.entries(TAB_CONFIG).map(([key, { label, spotlight }]) => (
                        <div
                            key={key}
                            className={cx("RagChatbotDetailPage__tab", { active: tab === key, spotlight })}
                            onClick={() => setTab(key)}
                        >
                            {label} {spotlight && <span className="RagChatbotDetailPage__tab--spotlight">New</span>}
                        </div>
                    ))}
                </div>
                <CodeIntegration tab={tab} chatbot={chatbot} accessToken={accessToken} />
            </div>
        </div>
    );
}

const getCodeForTab = (tab, chatbot, accessToken) => {
    const { model, llm_endpoint, max_tokens, id, temperature } = chatbot;
    const link = (llm_endpoint || '').split('/v1')[0];
    const iframeUrl = getRagChatbotIframeUrl(chatbot, accessToken);
    const standaloneUrl = getRagChatbotIframeUrl(chatbot, accessToken, true);
    const discordUrl = 'https://discord.com/oauth2/authorize?client_id=1316089787257458729&permissions=274877975552&integration_type=0&scope=bot';

    const codeMap = {
        curl: () => getCurlCode(id, accessToken, max_tokens, temperature),
        iframe: () => `<iframe src="${iframeUrl}"></iframe>`,
        standalone: () => standaloneUrl,
        widget: () => getWidgetCode(id),
        discord: () => discordUrl
    };

    return (codeMap[tab] || (() => ''))();
};

const CodeIntegration = ({ tab, chatbot, accessToken }) => {
    const tabConfig = TAB_CONFIG[tab];
    const [copied, setCopied] = useState(false);
    const [code, setCode] = useState(() => getCodeForTab(tab, chatbot, accessToken));
    const apiUrl = `${Hosts.CHATBOT_API}/chatbot/${chatbot.id}/chat/completions`

    useEffect(() => {
        setCode(getCodeForTab(tab, chatbot, accessToken));
    }, [tab, chatbot, accessToken]);

    const handleCopy = () => {
        if (copied) return;
        navigator.clipboard.writeText(code)
            .then(() => {
                setCopied(true);
                toast.success('Code Copied!')
                setTimeout(() => setCopied(false), 2000);
            })
            .catch((err) => {
                console.error('Failed to copy:', err);
            });
    }

    const handleOpen = () => {
        window.open(code, '_blank');
    }

    return (
        <div className='AiServicePage__tab-content'>
            <div className='RagChatbotDetailPage integration'>
                <div className='RagChatbotDetailPage__code-content'>
                    <div className='RagChatbotDetailPage__code-content--title'>
                        {TAB_CONFIG[tab]?.title}
                    </div>
                    <div className='RagChatbotDetailPage__code-content--code'>
                        <SyntaxHighlighter
                            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                            language={TAB_CONFIG[tab]?.language}
                            style={customStyle}>
                            {code}
                        </SyntaxHighlighter>
                    </div>
                    <Button
                        onClick={tabConfig.button === 'copy' ? handleCopy : handleOpen}
                        title={tabConfig.button === 'copy' ? (copied ? "Copied!" : "Copy") : "Open"}
                        color={'green-outline'}
                    />

                    {typeof tabConfig.extra === 'function' 
                        ? tabConfig.extra({apiUrl, accessToken}) 
                        : tabConfig.extra}
                </div>
            </div>
        </div>
    );
}

const getCurlCode = (chatbotId, accessToken, maxTokens, temperature) => `curl -X POST ${Hosts.CHATBOT_API}/chatbot/${chatbotId}/chat/completions \\
-H 'Content-Type: application/json' \\
-H "Authorization: Bearer ${accessToken}" \\
-d '{
  "messages": [
      {"role": "system", "content": "You are a helpful assistant."},
      {"role": "user", "content": "Hello, how are you?"}
  ],
  "max_tokens": ${maxTokens || 100},
  "temperature": ${temperature || 0.7},
  "stream": true
}'`;

const getWidgetCode = (chatbotId) => `<script>
    window.TEC_CHATBOT_WIDGET_CONFIG = {
        pages: [
            'http://mywebsite.com/page1',
            'http://mywebsite.com/page2',
        ],
        chatbotId: "${chatbotId}",
    };
    window.TEC_CHATBOT_WIDGET_ENABLED = true;

    var timestamp = new Date().getTime();
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = "https://integrate.thetaedgecloud.com/widget/widget-latest.js?timestamp=" + timestamp;
    document.head.appendChild(script);
</script>`;