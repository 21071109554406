import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { BackArrowIcon, DetachIcon } from "../../../components/Svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Features, Urls } from "../../../constants";
import { mergeUrl, useQuery } from "../../../utils/url";
import { AiTabs, AiTab } from '../../../components/AiTabs';
import Ai, { selectDeployment, selectAllVMs, selectInstancesFromRegion, selectRegionsFromInstances } from '../../../store/models/Ai';
import { getLinkByDeployment, convertUnit, checkActivePod } from '../../../utils';
import classNames from 'classnames';
import Loader from '../../../components/Loader';
import { Button } from '../../../components/Button';
import { Hosts, ModalTypes } from "../../../constants";
import AiStorage, { getFormateStorageSize, selectAllStorages } from '../../../store/models/AiStorage';
import { selectIsFeatureEnabled } from '../../../store/models/Organization';
import UIState from '../../../store/UIState';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import User from '../../../store/models/User';
import Select from 'react-select';



function GpuNodeUpdatePage() {
  const dispatch = useDispatch();
  const { projectId, id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState();
  const [selectedStorage, setSelectedStorage] = useState(null);
  const { t } = useQuery();
  const backPageUrl = t === 'list' ?
    mergeUrl(Urls.AI_GPU_NODE, { projectId }) :
    mergeUrl(Urls.AI_GPU_NODE_DETAIL_BASE, { projectId, id });

  let deployment = useSelector(state => selectDeployment(state, projectId, id));
  const volumes = useSelector(state => selectAllStorages(state));
  const vms = useSelector(state => selectAllVMs(state));
  const instances = useSelector(state => selectInstancesFromRegion(state));
  const storages = useSelector(state => selectAllStorages(state));
  const availableVms = Object.keys(vms).filter(vm => Object.keys(instances).includes(vms[vm].name)).map(id => vms[id]);
  const vmOptions = availableVms.map(vm => ({
    value: vm.id,
    label: `${vm.name} (CPU: ${vm.resources.cpu / 1000} Cores, Mem: ${convertUnit(vm.resources.mem, 'MB')}, Ephemeral storage: ${convertUnit(vm.resources.storage, 'GB')}, GPU: ${vm.resources['gpu_model']} x ${vm.resources.gpu})${vm.id === _.get(deployment, 'vm_id') ? ' *Current' : ''}`,
    price: Number(vm.price_hour),
    name: vm.name
  }))
  const [curVm, setCurVm] = useState(vmOptions.filter(o => o.value === _.get(deployment, 'vm_id'))[0] || vmOptions?.[0]);
  const [curVolumes, setCurVolumes] = useState(_.get(deployment, 'volumes') || []);

  const portKey = _.get(deployment, 'AdditionalPorts.0');
  const mapPort = _.get(deployment, `PortMappings.${portKey}`);
  const isStorageEnabled = useSelector(state => selectIsFeatureEnabled(state, Features.STORAGE));
  const isStopped = !!id;
  let userId = isStopped ? _.get(deployment, 'UserID') : _.get(deployment, 'UserID');
  if (!userId || userId.startsWith('prj')) userId = null;
  else userId = 'usr_' + userId;

  const nameRef = useRef();
  const vmRef = useRef();

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [projectId]);


  useEffect(() => {
    const vmId = _.get(deployment, 'vm_id');
    const availableVms = Object.keys(vms).filter(vm => Object.keys(instances).includes(vms[vm].name)).map(id => vms[id]);
    const vmOptions = availableVms.map(vm => ({
      value: vm.id,
      label: `${vm.name} (CPU: ${vm.resources.cpu / 1000} Cores, Mem: ${convertUnit(vm.resources.mem, 'MB')}, Ephemeral storage: ${convertUnit(vm.resources.storage, 'GB')}, GPU: ${vm.resources['gpu_model']} x ${vm.resources.gpu})${vm.id === _.get(deployment, 'vm_id') ? ' *Current' : ''}`,
      price: Number(vm.price_hour),
      name: vm.name
    }))
    setCurVm(vmOptions.filter(o => o.value === vmId)[0] || vmOptions?.[0]);
  }, [_.get(deployment, 'vm_id'), vms, instances])

  const fetchData = async () => {
    setLoading(true);
    if (error) setError();
    try {
      let res = await dispatch(Ai.actions.fetchDeploymentBaseDetail(projectId, id));
      let region = _.get(res, 'selected_region');
      if (region) {
        await dispatch(Ai.actions.fetchInstancesFromRegion(projectId, region))
      }

      if (Object.keys(volumes).length === 0) {
        await dispatch(AiStorage.actions.fetchStorages(projectId));
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleConfirm() {
    let pathSet = new Set();
    for (let v of curVolumes) {
      if (pathSet.has(v.mountPath)) {
        setError('Persistent volume mount path must be unique');
        return;
      }
      pathSet.add(v.mountPath);
    }
    try {
      setUpdating(true);
      let nickname = nameRef.current.value;
      let vm = curVm.value;
      let obj = {
        project_id: projectId,
        name: _.get(deployment, 'name'),
        annotations: {
          ..._.get(deployment, 'annotations'),
          nickname
        },
        vm_id: vm,
        volumes: JSON.stringify(curVolumes),
        selected_region: _.get(deployment, 'selected_region')
      }
      await dispatch(Ai.actions.updateDeploymentBaseDetail(id, obj));
      setUpdating(false);
      toast.success('The deployment has been updated.')
      await fetchData();
      history.push(backPageUrl);
    } catch (e) {
      setUpdating(false);
      toast.success(`Something went wrong. Error: ${e.message}`)
      setError(e.message);
    } finally {

    }
  }

  const onSelectVolume = (selectedOption) => {
    if (selectedOption && selectedOption.value === 'create_new') {
      dispatch(UIState.actions.showModal(ModalTypes.MANAGE_STORAGE, { region: deployment.selected_region }));
      // Reset the select input
      setSelectedStorage(null);
    } else {
      setSelectedStorage(selectedOption);
    }
  };

  const handleAddVolume = () => {
    if (!selectedStorage) return;
    const generateUniquePath = () => {
      let newPath;
      let pathIndex = 1;
      do {
        newPath = `/mnt/data${pathIndex}`;
        pathIndex++;
      } while (curVolumes.some(volume => volume.mountPath === newPath));
      return newPath;
    };
    setCurVolumes([...curVolumes, {
      region: selectedStorage.region,
      volumeId: selectedStorage.value,
      mountPath: generateUniquePath()
    }])
    setSelectedStorage(null);
  }

  const handleDetachVolume = (id) => {
    setCurVolumes(volumes => volumes.filter(vm => vm.volumeId !== id));
  }

  const updateMountPath = (e, suffix) => {
    const newMountPath = e.target.value;
    setCurVolumes((volumes) => {
      return volumes.map((volume) =>
        volume.volumeId === suffix ? { ...volume, mountPath: newMountPath } : volume
      )
    }
    );
  };

  return (<div className={'AiServicePage'}>
    <Link to={backPageUrl}>
      <div className={classNames('AiServicePage__back-button',
        { 'gpu-node-list': t === 'list', 'gpu-node-detail': t !== 'list' })}>
        <BackArrowIcon />
        <div className={'AiServicePage__back-button--label'}>
          {t === 'list' ? 'GPU Node List' : 'GPU Node Detail'}
        </div>
      </div>
    </Link>
    {!loading && deployment && <div className={'AiServicePage__header detail-update'}>
      <div className={'AiServicePage__header--title'}>Reconfigure</div>
      <div className={'AiServicePage__header--subtitle'}>{deployment.annotations.nickname || deployment.name}</div>
    </div>}
    {!loading && deployment && <div className={'AiServicePage__content new-deployment'}>
      {
        deployment &&
        <div className='AiServicePage__tab-content'>
          <div className='ModelDeploymentDetailPage__details'>
            <div className='ModelDeploymentDetailPage__details--row section'>
              <div className='ModelDeploymentDetailPage__details--section-title'>
                Basic
              </div>
            </div>
            {_.get(deployment, 'annotations.nickname') && <>
              <div className='ModelDeploymentDetailPage__details--row'>
                <div className='ModelDeploymentDetailPage__details--title'>
                  Name
                </div>
                <div className='ModelDeploymentDetailPage__details--value'>
                  <input className={"ManageGPUNodeModal__input update"}
                    placeholder={'Enter gpu node name'}
                    ref={nameRef}
                    defaultValue={_.get(deployment, 'annotations.nickname')}
                  />
                </div>
              </div>
            </>}
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Mechine Type
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                <Select
                  options={vmOptions}
                  className={"ManageGPUNodeModal__selector"}
                  styles={selectStyles}
                  placeholder={'Select Persistent Volume'}
                  value={curVm}
                  onChange={setCurVm}
                  ref={vmRef}
                />
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Region
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {deployment.selected_region || 'N/A'}
              </div>
            </div>

            {isStorageEnabled && <>
              <div className='ModelDeploymentDetailPage__details--row section'>
                <div className='ModelDeploymentDetailPage__details--section-title'>
                  STORAGE
                </div>
              </div>

              <div className='ModelDeploymentDetailPage__storage'>
                <div className='ModelDeploymentDetailPage__storage--header'>
                  <div className='ModelDeploymentDetailPage__storage--cell'>Name</div>
                  <div className='ModelDeploymentDetailPage__storage--cell mobile-hide'>ID</div>
                  <div className='ModelDeploymentDetailPage__storage--cell'>Volume size</div>
                  <div className='ModelDeploymentDetailPage__storage--cell'>Mounted at</div>
                  <div className='ModelDeploymentDetailPage__storage--cell'>Detach volume</div>
                </div>
                {curVolumes.length > 0 ? (
                  curVolumes.map((storage, i) => {
                    const storageDetail = _.find(volumes, volume => volume.Suffix === storage.volumeId);
                    return (
                      <div key={i} className='ModelDeploymentDetailPage__storage--row'>
                        <div className='ModelDeploymentDetailPage__storage--cell'>{storageDetail?.DisplayName}</div>
                        <div className='ModelDeploymentDetailPage__storage--cell mobile-hide'>{storageDetail?.Name}</div>
                        <div className='ModelDeploymentDetailPage__storage--cell'>{getFormateStorageSize(storageDetail)}</div>
                        <div className='ModelDeploymentDetailPage__storage--cell'>
                          {/* {storage.mountPath || '-'} */}
                          <input className={"ManageGPUNodeModal__input update short"}
                            placeholder={'Enter mount path'}
                            value={storage.mountPath}
                            onChange={e => updateMountPath(e, storage.volumeId)}
                          />
                        </div>
                        <div className='ModelDeploymentDetailPage__storage--cell'>
                          <div className='ModelDeploymentDetailPage__storage--button' onClick={() => handleDetachVolume(storage.volumeId)}>
                            <DetachIcon />
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className='ModelDeploymentDetailPage__storage--row empty'>
                    <div className='ModelDeploymentDetailPage__storage--cell' colSpan="5">No persistent volumes attached</div>
                  </div>
                )}
              </div>
              <div className='ModelDeploymentDetailPage__details--row'>
                <Select
                  options={[
                    ...Object.values(storages.filter(s => !curVolumes.some(volume => volume.volumeId === s.Suffix)))
                      .map(storage => ({
                        value: storage.Suffix,
                        label: `${storage.DisplayName}  |  ${storage.Name}  |  ${storage.Region}`,
                        region: storage.Region,
                        isDisabled: storage.Region !== _.get(deployment, 'selected_region')
                      })),
                    { value: 'create_new', label: "+ Create new volume" }
                  ]}
                  className={"ModelDeploymentDetailPage__storage--selector"}
                  styles={selectStyles}
                  placeholder={'Select Persistent Volume'}
                  value={selectedStorage}
                  onChange={onSelectVolume}
                />
                <div className={classNames('ModelDeploymentDetailPage__storage--create-button',
                  { active: selectedStorage })}
                  onClick={handleAddVolume}
                >+ Attach volume</div>
              </div>
            </>}
            {error && <div className={"ModelDeploymentDetailPage__details--row color-red"}>
              {error}
            </div>}
            <div className='ModelDeploymentDetailPage__details--row section'>
              <Button title={'Confirm'} color='green' onClick={handleConfirm} loading={updating} />
              <Button title={'Cancel'} color='transparent' onClick={() => { history.push(backPageUrl) }} />
            </div>
          </div>
        </div>
      }
    </div>}
    {loading && <div className={'EmptyState'}>
      <Loader size='large' color='grey' />
    </div>}
    {!loading && error && !deployment && <div className={"AiServicePage__error"}>
      {error}
      <Button onClick={fetchData}
        color={"green-outline"}
        title={"Retry"} />
    </div>}
  </div >
  );
}

export default GpuNodeUpdatePage;

const selectStyles = {
  container: styles => ({
    ...styles,
    flex: 1,
    minWidth: 390,
    height: 40,
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    boxShadow: isFocused ? '0 0 0 1px #18c99d' : 'none',
    color: '#18c99d',
    ':hover': {
      borderColor: '#18c99d',
      boxShadow: '0 0 0 1px #18c99d',
    },
    '.selected': {
      borderColor: '#18c99d',
      boxShadow: '0 0 0 1px #18c99d',
    }
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
    paddingLeft: 15,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: isDisabled ? '#636B91' : (isFocused ? '#18c99d' : (isSelected ? '#18c99d' : '#636B91')),
      opacity: isDisabled ? 0.5 : 1,
    };
  },
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, color: 'white', fontWeight: '500' }),
};