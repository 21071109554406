import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import CreatableSelect from 'react-select/creatable';
import Ai, { selectAllVMs, selectRegionsFromInstances, selectTrainingTemplate } from '../store/models/Ai';
import { getVMOptions, generateRandomId, formatPrice, getErrorMsg, generateRandomIdWithPrefix, getVmResourcesString, convertUnit } from '../utils';
import { selectCurrentProjectId } from '../store/models/Project';
import { ModalTypes } from '../constants';
import { selectCurrentUser, selectCurrentUserId } from '../store/models/User';
import UIState from '../store/UIState';
import { toast } from "react-toastify";
import { selectCurrentOrgId, selectIsFeatureEnabled } from '../store/models/Organization';
import Tooltip from '../components/Tooltip';
import { BackArrowIcon, QuestionMarkIcon } from '../components/Svg';
import CustomSelect from '../components/CustomSelect';
import AiStorage, { selectAllDetailedRegions, selectAllRegions, selectAllStorages } from '../store/models/AiStorage';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import StepBar from '../components/StepBar';
import classNames from 'classnames';
import { findSpan } from 'three-stdlib';

const selectStyles = {
  container: (styles, { isDisabled }) => ({
    ...styles,
    flex: 1,
    height: 40,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  control: (styles, { isDisabled, ...state }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    ':hover': {
      borderColor: 'white',
    },
    '.selected': {
      borderColor: 'white',
    },
    cursor: isDisabled ? 'not-allowed' : 'default',
    color: isDisabled ? '#636B91' : 'white',
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: isDisabled ? '#636B91' : (isFocused ? 'white' : (isSelected ? 'white' : data?.value === 'create_new' ? '#18C99D' : '#636B91')),
      opacity: isDisabled ? 0.5 : 1,
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles, color: "#fff" }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { isDisabled, ...state }) => ({
    ...styles,
    color: isDisabled ? '#636B91' : 'white',
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontWeight: '500'
  }),
};

const selectRegionsAndStorages = createSelector(
  selectAllRegions,
  selectAllStorages,
  (regions, storages) => ({
    regions: regions || [],
    storages: storages || {}
  })
);

export const ManageGPUNodeModal = ({ onRequestClose, deploymentId = null, shard = null, deployment }) => {
  const dispatch = useDispatch();
  const isEditing = !!deployment;

  const [loading, setLoading] = useState(false);
  const [loadingVMRegion, setLoadingVMRegion] = useState(false);
  const [errorVMRegion, setErrorVMRegion] = useState(null);
  const [error, setError] = useState(null);
  const [existingDeployment, setExistingDeployment] = useState(deployment);
  const userId = useSelector(state => selectCurrentUserId(state));
  const curUser = useSelector(state => selectCurrentUser(state));
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const trainingTemplate = useSelector(state => selectTrainingTemplate(state));
  const vmRegions = useSelector(state => selectRegionsFromInstances(state));
  const detailedRegions = useSelector(state => selectAllDetailedRegions(state));

  const imagesOptions = trainingTemplate ? trainingTemplate.container_images.map(img => ({ label: img, value: img })) : [];
  let VMs = useSelector(state => selectAllVMs(state));
  VMs = Object.keys(VMs).reduce((acc, key) => {
    if (!key.includes('vm_c')) {
      acc[key] = VMs[key];
    }
    return acc;
  }, {});
  let [suggestedVM, setSuggestedVM] = useState(_.get(trainingTemplate, 'suggested_vms.0'));
  let [selectedVM, setSelectedVM] = useState({});
  let [selectedRegion, setSelectedRegion] = useState();
  let [vmOptions, setVmOptions] = useState(getVMOptions(VMs, suggestedVM));
  let defaultVM = vmOptions.filter(o => o.value === suggestedVM)[0] || vmOptions[0];
  let [selectedVMOption, setSelectedVMOption] = useState(defaultVM);
  let [price, setPrice] = useState(formatPrice(VMs[suggestedVM || vmOptions[0].value].price_hour / 100));
  const [imageValue, setImageValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const defaultName = generateRandomIdWithPrefix('gpunode', 20);

  const nameRef = useRef();
  const imageRef = useRef();
  const sshPortRef = useRef();
  const httpPortRef = useRef();
  const vmRef = useRef();
  const sshKeyRef = useRef();
  const regionRef = useRef();
  const mountPathRef = useRef();

  const { regions, storages } = useSelector(selectRegionsAndStorages);
  const [selectedStorage, setSelectedStorage] = useState(null);

  const [dname, setDname] = useState('');
  const [httpPort, setHttpPort] = useState('');
  const enableStorage = !_.isEmpty(vmRegions);
  const steps = [{ name: 'Machine Type' }, { name: 'Region' }, { name: 'GPU Node' }];
  const [activeStep, setActiveStep] = useState(isEditing ? 2 : 0);

  // useEffect(() => {
  //   const fetchDeploymentData = async () => {
  //     if (isEditing) {
  //       try {
  //         const deploymentBase = await dispatch(Ai.actions.fetchDeploymentBaseDetail(projectId, deployment.ID));
  //         const volumes = await dispatch(AiStorage.actions.getDeploymentVolumes(projectId, shard, deploymentId));
  //         setExistingDeployment(deploymentBase);
  //         setImageValue(deploymentBase.ImageURL);
  //         setDname(deploymentBase.Annotations?.nickname || '');
  //         setHttpPort(deploymentBase.ContainerPort?.toString() || '');
  //       } catch (error) {
  //         console.error('Error fetching deploymentBase:', error);
  //         setError('Failed to fetch deploymentBase data');
  //       }
  //     }
  //   };

  //   fetchDeploymentData();
  // }, [deploymentId]);
  useEffect(() => {
    if (isEditing) setLoading(false);
  }, [isEditing])

  useEffect(() => {
    const fetchRegionsAndStorages = async () => {
      try {
        // Fetch regions if not already in the store
        if (regions.length === 0) {
          await dispatch(AiStorage.actions.fetchRegions());
        }
        // fetchRegionsFromInstances(selectedVMOption);

        // Fetch storages if not already in the store
        if (Object.keys(storages).length === 0) {
          await dispatch(AiStorage.actions.fetchStorages(projectId));
        }
      } catch (error) {
        console.error('Error fetching regions and storages:', error);
        toast.error('Failed to fetch regions and storages');
      }
    };

    if (!userId) return;
    fetchRegionsAndStorages();
  }, [projectId]);

  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
    return newInputValue;
  };

  const handleBlur = () => {
    if (inputValue) {
      // setValue();
      onImageChange({ label: inputValue, value: inputValue });
      setInputValue('');
    }
  };

  const onSubmit = async () => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP))
      return;
    }
    if (!isEditing && !imageRef.current.state.selectValue[0]) {
      imageRef.current.focus();
      setError("Container image can't be empty.");
      return;
    }
    let name = nameRef.current.value;
    let image = !isEditing && _.get(imageRef, 'current.state.selectValue[0].value');
    let sshPort = Number(sshPortRef.current.value);
    if (isNaN(sshPort)) {
      sshPortRef.current.focus();
      setError("SSH port must be a number.");
      return;
    }
    if (!sshPort) {
      sshPortRef.current.focus();
      setError("SSH port can't be empty.");
      return;
    }
    let httpPort = Number(httpPortRef.current.value);
    if (isNaN(httpPort)) {
      httpPortRef.current.focus();
      setError("HTTP port must be a number.");
      return;
    }
    let vm = selectedVM.id;
    // let vm = vmRef.current.state.selectValue[0].value;
    // let pwd = pwdRef.current.value;
    let sshKey = sshKeyRef.current.value;
    if (!sshKey) {
      sshKeyRef.current.focus();
      setError("SSH key can't be empty.");
      return;
    }

    const imageId = trainingTemplate.id;
    const tags = trainingTemplate.tags;
    if (name.length) {
      if (!/^[A-Za-z0-9]+$/.test(name)) {
        const errorStr = 'Invalid ID. The ID should only contain letters and numbers.'
        setError(errorStr);
        toast.error('Error: ' + errorStr);
        return;
      }
      if (name.length > 20) {
        const errorStr = 'Invalid ID. The length of the ID should be less than or equal to 20.'
        setError(errorStr);
        toast.error('Error: ' + errorStr)
        return;
      }
    }
    // let region = regionRef.current?.getValue()?.[0]?.value;
    let regionShard = _.get(detailedRegions.filter(o => o.region === selectedRegion), '0.shard');
    let persistentVolume = selectedStorage?.value;
    let mountPath = mountPathRef.current?.value;

    // if (!region) {
    //   regionRef.current.focus();
    //   setError("Region can't be empty.");
    //   return;
    // }

    setLoading(true);
    setError(null);
    try {
      const deploymentData = isEditing
        ? {
          project_id: projectId,
          annotations: {
            nickname: dname
          },
          volumes: persistentVolume ? [{
            volumeId: persistentVolume,
            region: selectedStorage.region,
            mountPath: mountPath || undefined,
          }] : undefined,
        }
        : {
          name: name,
          project_id: projectId,
          deployment_image_id: imageId,
          container_image: isEditing ? undefined : image || input,
          min_replicas: 1,
          max_replicas: 1,
          vm_id: vm,
          annotations: {
            tags: JSON.stringify(tags),
            nickname: dname
          },
          additional_ports: [sshPort],
          container_port: Number(httpPort),
          env_vars: {
            "SSH_PUBLIC_KEY": sshKey
          },
          require_env_vars: true,
          volumes: persistentVolume ? [{
            volumeId: persistentVolume,
            region: selectedStorage.region,
            mountPath: mountPath || undefined,
          }] : undefined,
          selected_region: selectedRegion,
          shard: Number(regionShard)
        };

      if (isEditing) {
        await dispatch(Ai.actions.updateDeployment(shard, deploymentId, deploymentData));
      } else {
        await dispatch(Ai.actions.createDeployment(deploymentData));
      }

      await dispatch(Ai.actions.fetchGpuNodes(projectId));
      onRequestClose();
    } catch (e) {
      setLoading(false);
      let error = getErrorMsg(e.message, onRequestClose, curUser, orgId, vm)
      setError(error);
      if (error === e.message) {
        toast.error(e.message)
      }
    }
  }

  // const fetchRegionsFromInstances = async (vm) => {
  //   try {
  //     return await dispatch(Ai.actions.fetchRegionsFromInstances(projectId, vm.name));
  //   } catch (error) {
  //     console.error('Error fetching regions from instances:', error);
  //     toast.error('Failed to fetch regions from instances');
  //   }
  // }

  // const onVMChange = (v) => {
  //   fetchRegionsFromInstances(v);
  //   setPrice(formatPrice(VMs[v.value].price_hour / 100));
  //   setSelectedVMOption(v)
  // }

  const onImageChange = (v) => {
    setImageValue(v);
    const index = trainingTemplate.container_images.findIndex(img => img === v.value);
    // let newSuggestedVM = _.get(trainingTemplate, `suggested_vms.${index}`)
    // let newOptions = getVMOptions(VMs, newSuggestedVM)
    if (index > -1) {
      setHttpPort(_.get(trainingTemplate, 'container_port'));
    } else {
      setHttpPort('');
    }

    // setVmOptions(newOptions);
    // const vm = newOptions.filter(o => o.value === newSuggestedVM)[0] || newOptions[0];
    // setSelectedVMOption(vm);
    // fetchRegionsFromInstances(vm);
    // setPrice(formatPrice(VMs[suggestedVM || newOptions[0].value].price_hour / 100));
    // setSuggestedVM(newSuggestedVM);
  }

  const onSelectVolume = (selectedOption) => {
    if (selectedOption && selectedOption.value === 'create_new') {
      dispatch(UIState.actions.showModal(ModalTypes.MANAGE_STORAGE, { region: selectedRegion }));
      // Reset the select input
      setSelectedStorage(null);
    } else {
      setSelectedStorage(selectedOption);
    }
  };

  const handleSelectVM = async (vm) => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP))
      return;
    }
    try {
      setLoadingVMRegion(true);
      setError();
      // let regionRes = await fetchRegionsFromInstances(selectedVM);
      let regionRes = await dispatch(Ai.actions.fetchRegionsFromInstances(projectId, vm.name))
      let s = 0;
      Object.keys(regionRes).forEach(regionKey => {
        let region = regionRes[regionKey];
        Object.keys(region).forEach(k => {
          s += region[k];
        })
      })
      if (s === 0) {
        setError('There are no machines of this type currently available. Please try again later. Thank you for your patience as we look into increasing the machines we have for this type.');
      } else {
        setSelectedVM(vm);
        setActiveStep(v => v + 1);
      }
      setLoadingVMRegion(false);
    } catch (e) {
      console.log('handleNext error:', e.message);
      setLoadingVMRegion(false);
    }
  }

  const handleNext = async () => {
    try {
      setLoadingVMRegion(true);
      setError();
      // let regionRes = await fetchRegionsFromInstances(selectedVM);
      let regionRes = await dispatch(Ai.actions.fetchRegionsFromInstances(projectId, selectedVM.name))
      let s = 0;
      Object.keys(regionRes).forEach(regionKey => {
        let region = regionRes[regionKey];
        Object.keys(region).forEach(k => {
          s += region[k];
        })
      })
      if (s === 0) {
        setError('There are no machines of this type currently available. Please try again later. Thank you for your patience as we look into increasing the machines we have for this type.');
      } else {
        setActiveStep(v => v + 1);
      }
      setLoadingVMRegion(false);
    } catch (e) {
      console.log('handleNext error:', e.message);
      setLoadingVMRegion(false);
    }
  }

  const handleNextTwo = () => {
    setError();
    if (!selectedRegion) {
      setError('Please select a region to continue');
      return;
    }
    setActiveStep(2);
  }

  return (
    <Modal className={cx("ManageGPUNodeModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle align={'row'}>
          {isEditing ? 'Edit' : 'Create New'} GPU Node with SSH Access
          <Tooltip tooltip={'Open documentation'}>
            <a href="https://docs.thetatoken.org/docs/edgecloud-ai-training-with-gpu-nodes" target='_blank'>
              <QuestionMarkIcon />
            </a>
          </Tooltip>
        </ModalTitle>
      </ModalHeader>
      {activeStep !== 0 && <div className='modal-back-button' onClick={() => {
        if (activeStep === 1) setSelectedVM({});
        if (activeStep === 2) setSelectedRegion();
        setActiveStep(v => v - 1)
      }}>
        <BackArrowIcon />
        <span>Back</span>
      </div>}

      {(false)
        ? <Loader />
        : <>
          <div className='ManageGPUNodeModal__row'>
            <StepBar steps={steps} activeStep={activeStep} className={'ManageGPUNodeModalStepBar'} />
          </div>
          {!isEditing && <div className='ManageGPUNodeModal__row'>
            {activeStep === 0 && <div className='ManageGPUNodeModal__step'>Step 1. Select the Machine Type</div>}
            {activeStep === 1 && <div className='ManageGPUNodeModal__step'>
              Step 2. Select the Region
              <div className='ManageGPUNodeModal__step--info'>
                {`Machine Type: ${selectedVM.name} (${getVmResourcesString(selectedVM.resources)}) $${selectedVM.price_hour / 100}/hr`}
              </div>
            </div>}
            {activeStep === 2 && <div className='ManageGPUNodeModal__step'>
              Step 3. Configure the GPU Node
              <div className='ManageGPUNodeModal__step--info'>
                {`Machine Type: ${selectedVM.name} (${getVmResourcesString(selectedVM.resources)})`}
              </div>
              <div className='ManageGPUNodeModal__step--info'>
                {`Region: ${selectedRegion}`}
              </div>
            </div>}
          </div>}
          <ModalContent className={classNames(`ManageGPUNode`, { 'visable': activeStep === 2 })}>
            {activeStep === 0 && <>
              {!loadingVMRegion ?
                Object.keys(VMs).map((k, i) => {
                  const vm = VMs[k];
                  return <div className='ManageGPUNodeModal__row' key={i}>
                    <div className={classNames('ManageGPUNodeModal__vm-button', { 'active': vm.id === selectedVM.id })}
                      onClick={() => { handleSelectVM(vm) }}>
                      <div className='ManageGPUNodeModal__vm-button--left'>
                        <div className='ManageGPUNodeModal__vm-button--title'>
                          <div className='ManageGPUNodeModal__vm-button--name'>{`${vm.name}`}</div>
                          <div className='ManageGPUNodeModal__vm-button--gpu'>{` (${vm.resources['gpu_model']} x ${vm.resources.gpu})`}</div>
                        </div>
                        <div className='ManageGPUNodeModal__vm-button--subtitle'>
                          <div className='ManageGPUNodeModal__vm-button--cpu'>{`CPU: ${vm.resources.cpu / 1000} Cores`}</div>
                          <div className='ManageGPUNodeModal__vm-button--mem'>{`Mem: ${convertUnit(vm.resources.mem, 'MB')}`}</div>
                          <div className='ManageGPUNodeModal__vm-button--storage'>{`Ephemeral storage: ${convertUnit(vm.resources.storage, 'GB')}`}</div>
                          {/* {`CPU: ${vm.resources.cpu / 1000} Cores, Mem: ${convertUnit(vm.resources.mem, 'MB')}, Ephemeral storage: ${convertUnit(vm.resources.storage, 'GB')}`} */}
                        </div>
                      </div>
                      <div className='ManageGPUNodeModal__vm-button--right'>
                        <div className='ManageGPUNodeModal__vm-button--price'>{`$${(vm.price_hour / 100)} per hour`}</div>
                      </div>
                    </div>
                  </div>
                }) : <Loader />}
            </>}
            {activeStep === 1 && <>
              {detailedRegions.map((region, i) => {
                const disabled = Object.keys(vmRegions).indexOf(region.region) < 0;
                return <div className='ManageGPUNodeModal__row' key={i}>
                  <div className={classNames('ManageGPUNodeModal__vm-button',
                    {
                      'active': region.region === selectedRegion,
                      'disabled': disabled
                    })}
                    onClick={() => {
                      if (disabled) return;
                      setSelectedRegion(region.region)
                      setActiveStep(2);
                    }}>
                    <div className='ManageGPUNodeModal__vm-button--title region'>
                      <div className='ManageGPUNodeModal__vm-button--name'>{region.region}</div>
                    </div>
                  </div>
                </div>
              })}
            </>}
            {activeStep === 2 && <>
              <div className='ManageGPUNodeModal__row'>
                <div className='ManageGPUNodeModal__select-wrap name'>
                  <div className='ManageGPUNodeModal__select-label'>GPU Node ID</div>
                  <input className={"ManageGPUNodeModal__input"}
                    placeholder={'Enter gpu node ID'}
                    ref={nameRef}
                    defaultValue={isEditing ? existingDeployment.Suffix : defaultName}
                    disabled={true}
                  />
                </div>
                <div className='ManageGPUNodeModal__select-wrap name'>
                  <div className='ManageGPUNodeModal__select-label'>GPU Node Name&nbsp;<div className='text-optional'>(optional)</div></div>
                  <input className={"ManageGPUNodeModal__input"}
                    placeholder={'Enter gpu node name'}
                    value={dname}
                    onChange={(e) => setDname(e.target.value)}
                  />
                </div>
              </div>
              <div className='ManageGPUNodeModal__row'>
                <div className='ManageGPUNodeModal__select-wrap image'>
                  <div className='ManageGPUNodeModal__select-label'>GPU Node Image</div>
                  {isEditing ? <div>{imageValue}</div> : <CreatableSelect
                    options={imagesOptions}
                    className={"ManageGPUNodeModal__selector"}
                    placeholder={"Container image with sshd, e.g. thetalabsorg/ubuntu-sshd:latest"}
                    styles={selectStyles}
                    onChange={onImageChange}
                    ref={imageRef}
                    isDisabled={isEditing}
                    onInputChange={handleInputChange}
                    onBlur={handleBlur} // Handle blur event
                    // onMenuClose={handleMenuClose} // Handle menu close event
                    value={imageValue} // Controlled value
                    inputValue={inputValue} // Controlled input value
                  // onBlur={handleOnBlur}
                  // inputValue={input}
                  // onInputChange={(value, action) => {
                  //   if (action?.action == 'input-blur' || action?.action == 'menu-close') {
                  //     // setInput(value);
                  //     console.log('value:', value)
                  //     imageRef.current.selectOption({ label: value, value });
                  //     // imageRef.current.selectOption(value);
                  //   }
                  // }}
                  />}
                  {/* <Select options={imagesOptions}
              className={"ManageGPUNodeModal__selector"}
              defaultValue={imagesOptions[0]}
              styles={selectStyles}
              placeholder={'Select Image'}
              isDisabled={imagesOptions.length < 2}
              onChange={onImageChange}
              ref={imageRef}
            /> */}
                </div>
              </div>
              <div className='ManageGPUNodeModal__row'>
                <div className='ManageGPUNodeModal__select-wrap ports'>
                  <div className='ManageGPUNodeModal__select-label'>SSH Port</div>
                  <input className={"ManageGPUNodeModal__input"}
                    placeholder={'Enter SSH port'}
                    ref={sshPortRef}
                    defaultValue={isEditing ? existingDeployment.AdditionalPorts[0] : 22}
                    disabled={isEditing}
                  />
                  <div className='ManageGPUNodeModal__select-label'>HTTP Port&nbsp;<div className='text-optional'>(optional)</div></div>
                  <input className={"ManageGPUNodeModal__input"}
                    placeholder={'Enter HTTP port'}
                    ref={httpPortRef}
                    value={httpPort}
                    defaultValue={isEditing ? existingDeployment.ContainerPort : ''}
                    disabled={isEditing}
                    onChange={(e) => setHttpPort(e.target.value)}
                  />
                </div>
                <div className='ManageGPUNodeModal__select-wrap ssh'>
                  <div className='ManageGPUNodeModal__select-label'>SSH Public Key. How to&nbsp;<a href="https://git-scm.com/book/en/v2/Git-on-the-Server-Generating-Your-SSH-Public-Key"
                    target='_blank'>generate a public key.</a></div>
                  <textarea className={"ManageGPUNodeModal__textarea"}
                    placeholder={'ssh-rsa AAAAB...... (content of your ~/.ssh/id_rsa.pub file)'}
                    ref={sshKeyRef}
                    defaultValue={isEditing ? existingDeployment.EnvVars?.SSH_PUBLIC_KEY : ''}
                    disabled={isEditing}
                  />
                </div>
              </div>
              {/* <div className='ManageGPUNodeModal__row'>
              <div className='ManageGPUNodeModal__select-wrap vms'>
                <div className='ManageGPUNodeModal__select-label'>Machine type</div>
                <CustomSelect options={vmOptions}
                  className={"ManageGPUNodeModal__selector"}
                  value={selectedVMOption}
                  styles={selectStyles}
                  placeholder={'Select Machine type'}
                  isDisabled={vmOptions.length < 2 || isEditing}
                  onChange={onVMChange}
                  selectRef={vmRef}
                  isOptionDisabled={(option) => {
                    return option.price < Number(VMs[suggestedVM || vmOptions[0].value].price_hour)
                  }}
                />
              </div>
            </div> */}
              {/* <div className='ManageGPUNodeModal__row'>
                <div className='ManageGPUNodeModal__select-wrap location'>
                  <div className='ManageGPUNodeModal__select-label'>Region</div>
                  <CustomSelect
                    options={Object.keys(vmRegions).map(region => ({ value: region, label: region }))}
                    className={"ManageGPUNodeModal__selector"}
                    styles={selectStyles}
                    placeholder={'Select Region'}
                    selectRef={regionRef}
                  />
                </div>
              </div> */}
              {enableStorage && <div className='ManageGPUNodeModal__row last'>
                <div className='ManageGPUNodeModal__select-wrap persistent-volume'>
                  <div className='ManageGPUNodeModal__select-label'>Persistent Volume (optional)</div>
                  <CustomSelect
                    options={[
                      ...Object.values(storages).map(storage => ({
                        value: storage.Suffix,
                        label: `${storage.DisplayName}  |  ${storage.Name}  |  ${storage.Region}`,
                        region: storage.Region,
                        isDisabled: !vmRegions?.[storage.Region]
                      })),
                      { value: 'create_new', label: "+ Create new volume" }
                    ]}
                    className={"ManageGPUNodeModal__selector"}
                    styles={selectStyles}
                    placeholder={'Select Persistent Volume'}
                    value={selectedStorage}
                    onChange={onSelectVolume}
                    isClearable
                  />
                </div>
                <div className='ManageGPUNodeModal__select-wrap mount-path'>
                  <div className='ManageGPUNodeModal__select-label'>Mounted to (optional)</div>
                  <input
                    className={"ManageGPUNodeModal__input"}
                    placeholder={'Enter mount path'}
                    ref={mountPathRef}
                    defaultValue={isEditing ? existingDeployment.volumes?.[0]?.mountPath : '/mnt/data1'}
                  />
                </div>
              </div>}
              {/* <div className='ManageGPUNodeModal__price-info'>
              <div className='ManageGPUNodeModal__price-info--note'>
                Cost Estimation
              </div>
              <div className='ManageGPUNodeModal__price-info--price'>
                ~ ${price} per hour.
              </div>
            </div> */}
            </>}
          </ModalContent>
          {error && <div className={cx("ManageGPUNodeModal__error")}>{error}</div>}
          <ModalActions>
            {activeStep === 0 && <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />}

            {activeStep === 1 && <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />}
            {activeStep === 2 && <>
              <Button color={"green"} onClick={onSubmit} title={isEditing ? "Update GPU Node" : "Create GPU Node"} loading={loading} />
              <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
            </>}
          </ModalActions>
        </>}

    </Modal >
  );
}