import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { EllipsisIcon, CopyIcon, WarningIcon } from "../../../components/Svg";
import { ModalTypes, Urls } from '../../../constants';
import UIState from '../../../store/UIState';
import Ai, { selectAllGpuNodes, selectAllVMs } from '../../../store/models/Ai';
import { getLinkByDeployment, checkActiveSSH, getVmResourcesString, getStatus, getStatusString } from '../../../utils';
import { getStatusSum, checkActivePod, age, checkDeploymentsReady } from '../../../utils';
import classNames from 'classnames';
import { Button } from '../../../components/Button';
import Loader from '../../../components/Loader';
import { selectCurrentUserId } from '../../../store/models/User';
import { selectCurrentProjectId } from '../../../store/models/Project';
import _ from "lodash";
import { FaCheckCircle, FaEdit, FaTimesCircle } from "react-icons/fa";
import { selectAppStarted } from '../../../store/AppState';
import ApiKeyNote from '../../../components/ApiKeyNote';
import Tooltip from "../../../components/Tooltip";
import { mergeUrl } from "../../../utils/url";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";


function GPUNodePage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [curDir, setCurDir] = useState('');
  const [curType, setCurType] = useState('');
  const appStarted = useSelector(state => selectAppStarted(state));
  const userId = useSelector(state => selectCurrentUserId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const gpuNodes = useSelector(state => selectAllGpuNodes(state, projectId));
  const vms = useSelector(state => selectAllVMs(state));
  const hasNoGpuNodes = (!_.isNil(gpuNodes) && _.isEmpty(gpuNodes));
  const [activePopup, setActivePopup] = useState(-1);
  const history = useHistory();
  let timeoutId;

  async function fetchData() {
    try {
      setError()
      const gpuNodes = await dispatch(Ai.actions.fetchGpuNodes(projectId));
      setLoading(false);
      if (!checkDeploymentsReady(gpuNodes)) {
        timeoutId = setTimeout(fetchData, 1000 * 5)
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!appStarted || _.isNil(userId)) return;
    setLoading(true);
    fetchData();
    return () => { clearTimeout(timeoutId) };
  }, [projectId, Object.keys(gpuNodes).length]);


  const handleOpenModel = () => {
    dispatch(UIState.actions.showModal(ModalTypes.MANAGE_GPU_NODE))
  }

  const handlePopup = (e, i) => {
    e.stopPropagation();
    e.preventDefault();
    if (i === -1) {
      setActivePopup(i)
      return;
    }
    setActivePopup(v => {
      if (v === i) return -1;
      return i;
    })
  }

  const handleDelete = (gpuNode) => {
    const onConfirm = async () => {
      try {
        if (gpuNode.Suffix) {
          await dispatch(Ai.actions.deleteDeployment(projectId, gpuNode.Shard, gpuNode.Suffix));
        } else {
          await dispatch(Ai.actions.deleteDeploymentBase(projectId, gpuNode.ID));
        }
        await dispatch(Ai.actions.fetchGpuNodes(projectId));
        toast.success(`The deployment has been deleted.`)
      } catch (e) {
        toast.error(`Failed to delete the deployment. Error: ${e.message}`)
      }
    }
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: gpuNode.Name, onConfirm }));
    setActivePopup(-1);
  }

  const handleReconfigure = (gpuNode, isStopped) => {
    if (!isStopped) return;
    let url = mergeUrl(Urls.AI_GPU_NODE_UPDATE, { projectId, id: gpuNode.ID })
    history.push(url + '?t=list');
    // dispatch(UIState.actions.showModal(ModalTypes.MANAGE_GPU_NODE, { deployment: gpuNode }));
    setActivePopup(-1);
  }

  const handleStart = (gpuNode) => {
    const onConfirm = async () => {
      try {
        await dispatch(Ai.actions.startDeployment(projectId, gpuNode.ID));
        await fetchData();
        toast.success(`The deployment has been started.`)
      } catch (e) {
        toast.error(`Failed to start the deployment. Error: ${e.message}`)
      }
    }
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: "Start deployment",
      message: "Are you sure you want to start the deployment?",
      onConfirm: onConfirm,
      confirmLabel: "Yes",
      cancelLabel: "No",
      size: 's'
    }));
    setActivePopup(-1);
  }

  const handleStop = (gpuNode) => {
    const onConfirm = async () => {
      try {
        await dispatch(Ai.actions.stopDeployment(projectId, gpuNode.Shard, gpuNode.Suffix));
        await dispatch(Ai.actions.fetchGpuNodes(projectId));
        toast.success(`The deployment has been stopped.`)
      } catch (e) {
        toast.error(`Failed to stop the deployment. Error: ${e.message}`)
      }
    }
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 10 }}><WarningIcon /></div>
        Stop deployment
      </div>,
      message: <div style={{ padding: 10 }}>
        Data stored in ephemeral storage will be lost when a GPU node is stopped. To ensure data preservation, we recommend attaching a persistent volume to your GPU node during its creation. Persistent volumes safely retain data even when the node is stopped.
      </div>,
      onConfirm: onConfirm,
      confirmLabel: "Yes",
      cancelLabel: "No",
      size: 's'
    }));
    setActivePopup(-1);
  }

  const handleShow = (gpuNode) => {
    let portKey = _.get(gpuNode, 'AdditionalPorts.0');
    let mapPort = _.get(gpuNode, `PortMappings.${portKey}`);
    const msgString = `ssh -i ~/.ssh/id_rsa -p ${mapPort} root@${_.get(gpuNode, 'NodePublicIP')}`
    const message = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {msgString}
        <div className='AiServicePage__copy-button-wrap'
          onClick={() => {
            navigator.clipboard.writeText(msgString);
            toast.success("Copied to clipboard")
          }} >
          <CopyIcon />
        </div>
      </div>
    );
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: "SSH to the node",
      message: message
    }));
  }

  const navToDetail = (isStopped, baseId, suffix, shard) => {
    if (isStopped) {
      history.push(mergeUrl(Urls.AI_GPU_NODE_DETAIL_BASE, { projectId, id: baseId }));
      return;
    }
    history.push(mergeUrl(Urls.AI_GPU_NODE_DETAIL, { projectId, suffix, shard }));
  }

  const handleClickTitle = (e, type) => {
    e.preventDefault();
    setCurDir(curType === type ? (curDir === 'asc' ? 'desc' : 'asc') : 'desc');
    setCurType(type);
  }

  const getSortValue = (node, type) => {
    switch (type) {
      case 'name':
        return _.get(node, 'Annotations.nickname');
      case 'id':
        return _.get(node, 'Name');
      case 'region':
        return _.get(node, 'Region') || _.get(node, 'SelectedRegion') || 'N/A'
      case 'status':
        return getStatusString(node.PodPhase, node.Endpoint);
      default:
        return;
    }
  }

  return (<div className={'AiServicePage'}>
    <div className={'AiServicePage__header'}>
      <div className={'AiServicePage__header--title'}>
        GPU Node
        <ApiKeyNote type={'GPU nodes'} />
      </div>
      <div className={'AiServicePage__header--button'} onClick={handleOpenModel}>
        <div className={'AiServiceHeaderButtonIcon empty'}>
        </div>
        New GPU Node
      </div>
    </div>
    <div className={'AiServicePage__content'}>
      {loading &&
        <div className={'EmptyState'}>
          <Loader size={'large'} color={'green'} />
        </div>}
      {!hasNoGpuNodes && !loading && !error && <div className={'AiServicePage__table'}>
        <div className={'AiServicePage__table--header'}>
          <div className={'AiServicePage__table--header-row'}>
            <div className={'AiServicePage__table--cell title clickable'}
              onClick={(e) => handleClickTitle(e, 'name')}>
              Name
              <div className='AiServicePage__filter--sort'>
                {curType === 'name' ?
                  (curDir === 'desc' ? <div className='arrow-down'></div> : <div className='arrow-up'></div>) :
                  <div className='arrow-down-up'></div>}
              </div>
            </div>
            <div className={'AiServicePage__table--cell title clickable'}
              onClick={(e) => handleClickTitle(e, 'id')}>
              ID
              <div className='AiServicePage__filter--sort'>
                {curType === 'id' ?
                  (curDir === 'desc' ? <div className='arrow-down'></div> : <div className='arrow-up'></div>) :
                  <div className='arrow-down-up'></div>}
              </div>
            </div>
            <div className={'AiServicePage__table--cell view'}>SSH</div>
            <div className={'AiServicePage__table--cell view'}>HTTP Endpoint</div>
            <div className={'AiServicePage__table--cell status clickable'}
              onClick={(e) => handleClickTitle(e, 'status')}>
              Status
              <div className='AiServicePage__filter--sort'>
                {curType === 'status' ?
                  (curDir === 'desc' ? <div className='arrow-down'></div> : <div className='arrow-up'></div>) :
                  <div className='arrow-down-up'></div>}
              </div>
            </div>
            <div className={'AiServicePage__table--cell region clickable'}
              onClick={(e) => handleClickTitle(e, 'region')}>
              Region
              <div className='AiServicePage__filter--sort'>
                {curType === 'region' ?
                  (curDir === 'desc' ? <div className='arrow-down'></div> : <div className='arrow-up'></div>) :
                  <div className='arrow-down-up'></div>}
              </div>
            </div>
            <div className={'AiServicePage__table--cell status'}>
              Machine Type
            </div>
            <div className={'AiServicePage__table--cell ellipsis'}></div>
          </div>
        </div>
        <div className={'AiServicePage__table--body'}>
          {Object.keys(gpuNodes)
            .map((k) => gpuNodes[k])
            .sort((a, b) => {
              let valA = getSortValue(a, curType);
              let valB = getSortValue(b, curType);

              if (valA < valB) return curDir === 'asc' ? -1 : 1;
              if (valA > valB) return curDir === 'asc' ? 1 : -1;
              return 0;
            }).map((gpuNode, i) => {
              const httpPort = _.get(gpuNode, 'ContainerPort');
              const status = getStatus(gpuNode.PodPhase);
              const isStopped = status === 'stopped';
              return (
                <div className={`AiServicePage__table--row clickable`} key={i}
                  onClick={() => navToDetail(isStopped, gpuNode.ID, gpuNode.Suffix, gpuNode.Shard)}>
                  <div className={'AiServicePage__table--cell title'}>
                    <NameSection deployment={gpuNode} />
                  </div>
                  <div className={'AiServicePage__table--cell title'}>
                    {gpuNode.Name}
                  </div>
                  <div className={'AiServicePage__table--cell view'}>
                    <div className={classNames('AiServicePageViewButton', { active: checkActiveSSH(gpuNode) })}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isStopped) return;
                        handleShow(gpuNode)
                      }}>
                      Show
                    </div>
                  </div>
                  <div className={classNames('AiServicePage__table--cell view')}>
                    <div className={classNames('AiServicePageViewButton', { active: checkActivePod(gpuNode) && httpPort > 0 })}>
                      <a href={checkActivePod(gpuNode) && httpPort > 0 ? getLinkByDeployment(gpuNode) : ''} target='_blank' onClick={(e) => e.stopPropagation()} >
                        Open
                      </a>
                    </div>
                  </div>
                  <div className={`AiServicePage__table--cell status icon`}>{getStatusSum(gpuNode.PodPhase, gpuNode.Endpoint, false)}</div>
                  <div className={'AiServicePage__table--cell region'}>{_.get(gpuNode, 'Region') || _.get(gpuNode, 'SelectedRegion') || 'N/A'}</div>
                  <div className={'AiServicePage__table--cell status'}>
                    <Tooltip tooltip={<div className={"AiServicePage__tooltip"}>
                      {getVmResourcesString(vms[gpuNode.MachineType].resources)}
                    </div>}>{vms[gpuNode.MachineType].name}</Tooltip>
                  </div>
                  <div className={'AiServicePage__table--cell ellipsis'}>
                    <div className={'AiServicePageEllipsis'} onClick={(e) => handlePopup(e, i)}>
                      <EllipsisIcon />
                    </div>
                    {activePopup === i && <div className={'AiServicePageEllipsis__popup'} onClick={(e) => { e.stopPropagation() }}>
                      {isStopped && <div className={'AiServicePageEllipsis__popup--row'} onClick={() => handleStart(gpuNode)}>Start</div>}
                      {!isStopped && <div className={'AiServicePageEllipsis__popup--row'} onClick={() => handleStop(gpuNode)}>Stop</div>}
                      <div className={'AiServicePageEllipsis__popup--row'} onClick={() => handleDelete(gpuNode)}>Delete</div>
                      <div className={`AiServicePageEllipsis__popup--row ${isStopped ? '' : 'disabled'}`} onClick={() => handleReconfigure(gpuNode, isStopped)}>Reconfigure</div>
                    </div>}
                  </div>
                </div>
              )
            })}
        </div>
      </div>}
      {hasNoGpuNodes && !loading && !error &&
        <div className={'EmptyState'}>
          <div className={'EmptyState__title'}>No GPU Node</div>
          <div className={'EmptyState__subtitle'}>Create your first GPU Node with SSH access to get started</div>
          <Button size={'large'}
            title={'New GPU Node'}
            onClick={handleOpenModel}
          />
        </div>}
      {!loading && error && <div className={"AiServicePage__error"}>
        {error}
        <Button onClick={async () => {
          await fetchData();
          setLoading(false);
        }}
          color={"green-outline"}
          title={"Retry"} />
      </div>}
    </div>
  </div >
  );
}

const NameSection = ({ deployment }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const nameRef = useRef();
  const status = getStatus(deployment.PodPhase);
  const isStopped = status === 'stopped';
  const handleEdit = () => {
    setIsEditing(true);
  }
  const handleSubmit = async () => {
    let nickname = nameRef.current.value;
    let projectId = deployment.ProjectID;
    if (isStopped) {
      await dispatch(Ai.actions.updateDeploymentBaseDetail(deployment.ID, {
        project_id: projectId,
        name: _.get(deployment, 'Name'),
        annotations: {
          ..._.get(deployment, 'Annotations'),
          nickname
        },
        vm_id: _.get(deployment, 'MachineType'),
        volumes: JSON.stringify(_.get(deployment, 'Volumes')),
        selected_region: _.get(deployment, 'SelectedRegion')
      }))
    } else {
      await dispatch(Ai.actions.updateDeployment(deployment.Shard, deployment.Suffix, {
        project_id: projectId,
        annotations: {
          nickname: nickname,
          tags: deployment.Annotations.tags
        }
      }))
    }
    await dispatch(Ai.actions.fetchGpuNodes(projectId));
    setIsEditing(false);
  }
  const handleCancel = () => {
    setIsEditing(false);
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  return <div onClick={e => e.stopPropagation()} className='AiServicePage__name-warp'>
    {!isEditing ? deployment.Annotations.nickname || `\u00A0` :
      <input type='text' ref={nameRef} defaultValue={deployment.Annotations.nickname} onKeyDown={handleKeyDown} />}
    {isEditing ?
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon cancel' onClick={handleCancel} title="Cancel"><FaTimesCircle /></div>
        <div className='PageTitleBar__right--icon submit' onClick={handleSubmit} title="Submit"><FaCheckCircle /></div>
      </div> :
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon edit' onClick={handleEdit}><FaEdit /></div>
      </div>}
  </div>
}

export default GPUNodePage;